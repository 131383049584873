<template>
  <dialog-view
      title="Удалить профиль"
      :open="open"
      @cancel="$emit('update:open', false)"
      content-full-size
  >
    <template v-slot:content>
      <graphql-view
        query='
          query Query {
            SalaryTemplate {
              id
              name
              comment
              renMode
              ren
              refMode
              ref
              regMode
              reg
              rateMode
              rate
              isRateServiceDiscount
            }
          }
        '
        :queryVars="{}"
        :key="componentReloadKey"
      >
        <template v-slot:content="{data}">
          <v-list dense>
            <v-list-item v-for="(v, i) in data.SalaryTemplate" :key="i" @click="remove(v)">
              <v-list-item-content>
                <v-list-item-title>{{v.name}}</v-list-item-title>
                <div v-if="!$tools.isEmpty(v.comment)" class="mt-1">
                  <v-icon small>mdi-comment-text-outline</v-icon>
                  <div class="caption mt-n5 ml-5">{{v.comment}}</div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </graphql-view>
    </template>
  </dialog-view>
</template>

<script>
import DialogView from "@/components/DialogView.vue";
import GraphqlView from "@/components/GraphqlView.vue";
import axios from "axios";
import Api from "@/Api";

export default {
  components: {GraphqlView, DialogView},
  props:['open'],
  data: () => ({
    componentReloadKey: 0
  }),
  watch:{
    open() {
      this.reloadComponent();
    }
  },
  mounted() {
    
  },
  methods: {
    reloadComponent() {
      this.componentReloadKey += 1;
    },
    remove(v){
      axios.delete(Api.host+'/salary/template/'+v.id+'/').then(()=>{
        this.$emit('update:open', false);
        this.$store.commit('alertSuccess', 'Профиль удален');
      });
    }
  }
};
</script>