<template>
  <div>

    <div  v-if="loadState.isLoading" class="my-5 text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <template v-if="loadState.isSuccess">
      <v-list dense v-if="medPlans.length>0" class="mb-5">
        <v-subheader>Список активных планов лечения:</v-subheader>
        <v-list-item-group
          v-model="selectedPlan"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in medPlans"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$tools.date(item.plannedDate,'DD.MM.YYYY')+' ('+(item.name ? item.name : 'Без названия')+')'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <date :value.sync="dateFrom"  name="Планируемая дата родов" class="mt-2 mb-5"/>

    </template>
  </div> 
</template>
<script>
import Date from "@/componentsV2/base/Date.vue";
import State from "@/plugins/state";
import Api from "@/Api";

import moment from "moment";
export default {
 components:{Date},
 props: ['userId'],
  data: () => ({
    loadState: new State(),
    dateFrom: new moment().format('DD.MM.YYYY'),
    medPlans:[],
    selectedPlan:[]
  }),
  mounted(){
    this.load();
  },
  watch:{
    dateFrom(v){
      const currentDate = new moment();
      const pregnancyStart = moment(v,'DD.MM.YYYY').subtract(280, 'days');
      const days = moment(currentDate,'DD.MM.YYYY').diff(pregnancyStart,'d');

      function formatDuration(days) {
        const weeks = Math.floor(days / 7);
        const remainingDays = days % 7;

        let result = '';

        function getWeeksDeclension(weeks) {
          const lastDigit = weeks % 10;
          const lastTwoDigits = weeks % 100;

          if (lastDigit === 1 && lastTwoDigits !== 11) {
            return ' неделя';
          } else if (
            (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) &&
            (lastTwoDigits < 10 || lastTwoDigits > 20)
          ) {
            return ' недели';
          } else {
            return ' недель';
          }
        }

        if (weeks > 0) {
          result += weeks + getWeeksDeclension(weeks);
        }

        if (remainingDays > 0) {
          result += ' '+remainingDays + (remainingDays === 1 ? ' день' : remainingDays >= 2 && remainingDays <= 4 ? ' дня' : ' дней');
        }

        return result.trim();
      }

      this.$emit('calc',formatDuration(days));
    },
    selectedPlan(index){
      this.dateFrom = this.$tools.date(this.medPlans[index].plannedDate,'DD.MM.YYYY');
    }
  },
  methods: {
    load(){
      this.loadState.stateLoading();
      Api.graphql(`
          query Query(
            $user_id: Int
            $status: String
          ){
            MedPlan(
              user_id: $user_id
              status: $status
            ){
              name
              comment
              begin
              plannedDate
              id
            }  
          }
        `, {
          user_id:parseInt(this.userId),
          status:'active'
        }, (data)=>{
          
          function checkDate(date){
            const currentDate = new moment();

            let diff = moment(date).diff(currentDate, 'days');

            if (diff<0) {
              return false;
            } 

            return true;
          }
          
          this.medPlans = data.MedPlan.filter(item=>item.plannedDate!==null&&checkDate(item.plannedDate));

          this.loadState.stateSuccess();
        }, ()=>{
          this.loadState.stateError();
      });
    }
  }
}
</script>