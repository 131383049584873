<template>
  <v-sheet class="d-inline-block mt-n3">
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="medRecordEditorField">{{editorField.currentValue}}</div>
      </template>
      <v-sheet width="450">
        <div class="mb-2">
          <v-row no-gutters class="pt-2 px-2 mb-2">
            <v-col>
              <v-btn text small @click="$parent.fieldUpdateRemove(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-close</v-icon>Удалить значение
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text small @click="$parent.fieldUpdateMore(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-plus</v-icon>Дублировать значение
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
        </div>
        <v-text-field
            ref="text"
            v-model="value"
            v-mask="'##:##'"
            @click:append="update()"
            class="medRecordEditorFieldTextField ma-2"
            append-icon="mdi-check"
            :value="editorField.option"
            placeholder="Введите время"
            outlined small dense hide-details="auto"
        />
        <v-time-picker
            full-width
            v-model="time"
            format="24hr"
            landscape
        />
        <div>
          <v-list-item
              v-for="(option, index2) in editorField.options"
              :key="index2"
              @click="$parent.fieldUpdateSelect(editorField, option);isOpen=false">
            <v-list-item-title>
              {{ option.length===0 ? '(Пусто)' : option.value }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-sheet>
    </v-menu>
  </v-sheet>
</template>

<script>
import moment from "moment/moment";

export default {
  props:['editorField', 'editorFieldOpen'],
  data: () => ({
    isOpen:false,
    value:'',
    time:''
  }),
  mounted(){
    this.value = '';
    this.time = '';
  },
  watch:{
    isOpen(){
      this.value = '';
      setTimeout(()=>{
        if (this.isOpen)
          this.$refs.text.$el.querySelector('input').focus();
      }, 100);
    },
    time(){
      let d = moment(this.time, 'HH:mm');
      if (d.isValid()){
        this.value = d.format('HH:mm');
      }
    },
    value(){
      let d = moment(this.value, 'HH:mm');
      if (d.isValid() && this.value.length===5){
        this.time = '';
      }
    }
  },
  methods:{
    update(){
      let d = moment(this.value, 'HH:mm');
      if (d.isValid()){
        this.isOpen = false;
        this.editorField.customValue = d.format('HH:mm');
        this.$parent.fieldUpdateSelectCustom(this.editorField);
      } else {
        this.value = '';
      }
    }
  }
}
</script>
