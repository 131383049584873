<template>
    
    <table class="table" id="table" cellspacing="2" border="1" cellpadding="5" width="600" ref="table">
        <tr>
            <td v-for="title in header" :key="title" type="title"><b>{{title}}</b></td>
            <td class="controlCell"><b>Удалить</b></td>
        </tr>    
        <tr v-for="(parameter, index) in params" :key="index" :class="{ disabled: parameter.disabled }">  
            <template v-if="parameter.isTitle === true">
                <td align="left" colspan="4" type="title"><b>{{parameter.name}}</b></td>
            </template> 
            <template v-else>   
                <td align="left">{{parameter.name}}</td>
                
                <td align="center" v-on:click="showInput(index)" :bold="parameter.alert ? true : ''" :style="parameter.alert ? {color:'#ff5252'} : {color:'inherit'}">    
                    <input v-if="parameter.resultInput===true" type="text" v-model="params[index].result" @keyup.up="addResultUp(index-1)" @keyup.down="addResult(index+1)" @keyup.enter="addResult(index+1)" @blur="addResultUnfocus(index)" @focusout="addResultUnfocus(index)" :ref="'cell'+index">
                    <template v-if="parameter.resultInput===false">{{parameter.result}}</template>
                </td>
                <td align="center">{{parameter.norm}}</td>
                <td align="center" v-html="parameter.unit"></td>
                <td v-if="!parameter.disabled" class="controlCell" @click="params[index].disabled=true;convertation();"><v-icon color="#F44336">mdi-close</v-icon></td>
                <td v-if="parameter.disabled===true" class="controlCell" @click="params[index].disabled=false;convertation();"><v-icon color="#4CAF50">mdi-arrow-u-left-top</v-icon></td>
            </template> 
        </tr>    
    </table>    
 
</template>
<script>
import moment from "moment";

export default {
  props: ['medRecordValue','editorField','birth','gender'],
  data: () => ({
    age:null,
    date:null,
    days:0,
    header: [
        'Тест',
        'Результат',    
        'Норма',
        'Ед. измерен.',
    ],
    params: [],
    newMedRecordValue: null,
    firstLoad: false,
    focusChange: false,
    globalParams: [
        {
         name:'СОЭ',
         result:'',
         resultInput:true,
         unit:'мм/ч',
         norm: {
            m0_1:'0-8',
            m_2:'0-8', 
            m3_4:'0-8',
            m5_6:'0-8', 
            m7_9:'4-10',
            m10_12:'4-10',
            y1_6:'4-12',
            y6_12:'4-12',   
            y12_15:'4-15',
            a15ym:'1-10',
            a15yw:'2-15', 

         }
        },
        {
         name:'Гемоглобин (HB)',
         result:'',
         resultInput:true,
         unit:'g/l',
         norm: {
            m0_1:'110-171',
            m_2:'110-130', 
            m3_4:'110-141',
            m5_6:'110-141', 
            m7_9:'110-140',
            m10_12:'110-141',
            y1_6:'110-140',
            y6_12:'110-150',   
            y12_15:'110-150',
            a15ym:'110-173',
            a15yw:'110-155', 

         }
        },
        {
         name:'Лейкоциты (WBC)',
         result:'',
         resultInput:true,
         unit:'10^9/l',
         norm: {
            m0_1:'5,0-19,5',
            m_2:'5,5-18,0', 
            m3_4:'6,0-17,5',
            m5_6:'6,0-17,6', 
            m7_9:'6,0-17,7',
            m10_12:'6,0-17,8',
            y1_6:'5,5-14,5',
            y6_12:'4,5-13,5',   
            y12_15:'4,5-13,5',
            a15ym:'4,0-9,0',
            a15yw:'4,0-9,0', 

         }
        },
        {
         name:'Эритроциты (RBC)',
         result:'',
         resultInput:true,
         unit:'10^12/l',
         norm: {
            m0_1:'3,50-4,80',
            m_2:'3,50-4,80', 
            m3_4:'3,50-4,80',
            m5_6:'3,50-4,80', 
            m7_9:'3,050-4,90',
            m10_12:'3,050-4,90',
            y1_6:'3,50-4,50',
            y6_12:'3,50-4,70',   
            y12_15:'3,60-5,10',
            a15ym:'4,0-5,50',
            a15yw:'3,70-4,70', 

         }
        },
        {
         name:'Среднее содержание HB в эритроците (MCH)',
         result:'',
         resultInput:true,
         unit:'pg',
         norm: {
            m0_1:'24-33',
            m_2:'24-33', 
            m3_4:'24-33',
            m5_6:'24-33', 
            m7_9:'24-33',
            m10_12:'24-33',
            y1_6:'24-33',
            y6_12:'24-33',   
            y12_15:'24-33',
            a15ym:'24-33',
            a15yw:'24-33', 

         }
        },
        {
         name:'Концентрация HB в эритроците (MCHC)',
         result:'',
         resultInput:true,
         unit:'g/l',
         norm: {
            m:'324-375',
            f:'315-356', 
         }
        },
        {
         name:'Средний объем эритроцита (MCV)',
         result:'',
         resultInput:true,
         unit:'fl',
         norm: {
            y0_1:'80-100',
            y1_5:'80-100',
            y5_10:'80-100',
            y10_12:'80-100',
            m: {
                y12_15:'80-100',
                y15_18:'80-100',
                y18_45:'80-100',
                y45_65:'80-100',
                y65:'80-100'
            },
            f: {
                y12_15:'80-100',
                y15_18:'80-100',
                y18_45:'80-100',
                y45_65:'80-100',
                y65:'80-100'
            }
         }
        },
        {
         name:'Тромбоциты (PLT)',
         result:'',
         resultInput:true,
         unit:'10^9/l',
         norm: {
            m0_1:'180-400',
            m_2:'180-400', 
            m3_4:'180-400',
            m5_6:'180-400', 
            m7_9:'180-400',
            m10_12:'180-400',
            y1_6:'160-390',
            y6_12:'160-380',   
            y12_15:'160-360',
            a15ym:'180-360',
            a15yw:'180-360', 

         }
        },
        {
         name:'Ширина распределения тромбоцитов по объёму, PDW',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'10-20',
            m_2:'10-20', 
            m3_4:'10-20',
            m5_6:'10-20', 
            m7_9:'10-20',
            m10_12:'10-20',
            y1_6:'10-20',
            y6_12:'10-20',   
            y12_15:'10-20',
            a15ym:'10-20',
            a15yw:'10-20', 

         }
        },
        {
         name:'Средний объём тромбоцитов, MPV',
         result:'',
         resultInput:true,
         unit:'фл',
         norm: {
            m0_1:'7.40-10.40',
            m_2:'7.40-10.40', 
            m3_4:'7.40-10.40',
            m5_6:'7.40-10.40', 
            m7_9:'7.40-10.40',
            m10_12:'7.40-10.40',
            y1_6:'7.40-10.40',
            y6_12:'7.40-10.40',   
            y12_15:'7.40-10.40',
            a15ym:'7.40-10.40',
            a15yw:'7.40-10.40', 

         }
        },
        {
         name:'Цветовой показатель',
         result:'',
         resultInput:true,
         unit:'',
         norm: {
            m0_1:'0,85-1,15',
            m_2:'0,85-1,15', 
            m3_4:'0,85-1,15',
            m5_6:'0,85-1,15', 
            m7_9:'0,85-1,15',
            m10_12:'0,85-1,15',
            y1_6:'0,8-1,0',
            y6_12:'0,8-1,0',   
            y12_15:'0,8-1,0',
            a15ym:'0,82-1,05',
            a15yw:'0,82-1,05', 

         }
        },
        {
         name:'Гематокрит (HCT)',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'31,0-41,0',
            m_2:'31,0-41,0', 
            m3_4:'31,0-41,0',
            m5_6:'31,0-41,0', 
            m7_9:'31,0-41,0',
            m10_12:'31,0-41,0',
            y1_6:'32,0-42,0',
            y6_12:'33,0-43,0',   
            y12_15:'34,0-45,0',
            a15ym:'35,0-45,0',
            a15yw:'35,0-45,0', 

         }
        },
        {
         name:'Лейкоцитарная формула',
         resultInput:false,
         isTitle: true,
        },
        {
         name:'Палочкоядерные',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'0,5-4,0',
            m_2:'0,5-4,0', 
            m3_4:'0,5-4,0',
            m5_6:'0,5-4,0', 
            m7_9:'0,5-4,0',
            m10_12:'0,5-4,0',
            y1_6:'0,5-5,0',
            y6_12:'0,5-5,0',   
            y12_15:'0,5-6,0',
            a15ym:'1,0-6,0',
            a15yw:'1,0-6,0', 
         }
        },
        {
         name:'Сегментоядерные',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'15,0-45,0',
            m_2:'15,0-45,0', 
            m3_4:'15,0-45,0',
            m5_6:'15,0-45,0', 
            m7_9:'15,0-45,0',
            m10_12:'15,0-45,0',
            y1_6:'25,0-60,0',
            y6_12:'36,0-65,0',   
            y12_15:'40,0-65,0',
            a15ym:'47,0-72,0',
            a15yw:'47,0-72,0', 

         }
        },
        {
         name:'Нейтрофилы',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'20-70',
            m_2:'20-70', 
            m3_4:'20-70',
            m5_6:'20-70', 
            m7_9:'20-70',
            m10_12:'20-70',
            y1_6:'30-75',
            y6_12:'40-75',   
            y12_15:'40-75',
            a15ym:'40-75',
            a15yw:'40-75', 
         }
        },
        {
         name:'Эозинофилы',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'0,5-7,0',
            m_2:'0,5-7,0', 
            m3_4:'0,5-7,0',
            m5_6:'0,5-7,0', 
            m7_9:'0,5-7,0',
            m10_12:'0,5-7,0',
            y1_6:'0,5-7,0',
            y6_12:'0,5-7,0',   
            y12_15:'0,5-6,0',
            a15ym:'0,5-5,0',
            a15yw:'0,5-5,0', 

         }
        },
        {
         name:'Базофилы',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'0,0-1,0',
            m_2:'0,0-1,0', 
            m3_4:'0,0-1,0',
            m5_6:'0,0-1,0', 
            m7_9:'0,0-1,0',
            m10_12:'0,0-1,0',
            y1_6:'0,0-1,0',
            y6_12:'0,0-1,0',   
            y12_15:'0,0-1,0',
            a15ym:'0,0-1,0',
            a15yw:'0,0-1,0', 

         }
        },
        {
         name:'Моноциты',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'2,0-12,0',
            m_2:'2,0-12,0', 
            m3_4:'2,0-12,0',
            m5_6:'2,0-12,0', 
            m7_9:'2,0-12,0',
            m10_12:'2,0-12,0',
            y1_6:'2,0-10,0',
            y6_12:'2,0-10,0',   
            y12_15:'2,0-10,0',
            a15ym:'2,0-9,0',
            a15yw:'2,0-9,0', 

         }
        },
        {
         name:'Лимфоциты',
         result:'',
         resultInput:true,
         unit:'%',
         norm: {
            m0_1:'42,0-74,0',
            m_2:'42,0-74,0', 
            m3_4:'42,0-74,0',
            m5_6:'42,0-74,0', 
            m7_9:'38,0-72,0',
            m10_12:'38,0-72,0',
            y1_6:'26,0-60,0',
            y6_12:'24,0-54,0',   
            y12_15:'25,0-40,0',
            a15ym:'18,0-40,0',
            a15yw:'18,0-40,0', 

         }
        },
        // {
        //  name:'Процентное содержание моноцитов (MID)',
        //  result:'',
        //  resultInput:true,
        //  unit:'%',
        //  norm: {
        //     m0_1:'2,0-12,0',
        //     m_2:'2,0-12,0', 
        //     m3_4:'2,0-12,0',
        //     m5_6:'2,0-12,0', 
        //     m7_9:'2,0-12,0',
        //     m10_12:'2,0-12,0',
        //     y1_6:'2,0-12,0',
        //     y6_12:'2,0-10,0',   
        //     y12_15:'2,0-15,0',
        //     a15ym:'2,0-15,0',
        //     a15yw:'2,0-15,0', 

        //  }
        // },
        // {
        //  name:'Процентное содержание лимфоцитов (LYM)',
        //  result:'',
        //  resultInput:true,
        //  unit:'%',
        //  norm: {
        //     m0_1:'38,0-76,0',
        //     m_2:'38,0-76,0', 
        //     m3_4:'38,0-76,0',
        //     m5_6:'38,0-76,0', 
        //     m7_9:'38,0-76,0',
        //     m10_12:'38,0-76,0',
        //     y1_6:'26,0-60,0',
        //     y6_12:'24,0-54,0',   
        //     y12_15:'15,0-50,0',
        //     a15ym:'15,0-50,0',
        //     a15yw:'15,0-50,0', 

        //  }
        // },
        // {
        //  name:'Процентное содержание гранулоцитов (GRA)',
        //  result:'',
        //  resultInput:true,
        //  unit:'%',
        //  norm: {
        //     m0_1:'15,0-30,0',
        //     m_2:'15,0-30,0', 
        //     m3_4:'15,0-30,0',
        //     m5_6:'15,0-30,0', 
        //     m7_9:'15,0-30,0',
        //     m10_12:'15,0-30,0',
        //     y1_6:'25,0-60,0',
        //     y6_12:'35,0-72,0',   
        //     y12_15:'35,0-80,0',
        //     a15ym:'35,0-80,0',
        //     a15yw:'35,0-80,0', 

        //  }
        //},
    ],
  }),
  watch:{
    medRecordValue(){
        if(this.focusChange === false) {
            this.load(true);
        }
    },  
    params: {
        deep: true,
        handler: function() {
            this.resultCheck();
        }
    }
  },
  mounted() {
   this.load();
  },
  methods: {
    load(force=false){
        const tableRender = new Promise((resolve) => {
            var now = moment(new Date());
            var birth = moment(this.birth, 'DD.MM.YYYY');
            this.days = now.diff(birth, 'days');    
            this.params = [];
            let norm;
            this.globalParams.forEach(parameter =>{
                if (parameter.name == 'Средний объем эритроцита (MCV)') {  
                    if (this.days<365) {
                        norm = parameter.norm.y0_1
                    }
                    if (this.days>=365&&this.days<1825) {
                        norm = parameter.norm.y1_5
                    }
                    if (this.days>=1825&&this.days<3650) {
                        norm = parameter.norm.y5_10
                    }
                    if (this.days>=3650&&this.days<4320) {
                        norm = parameter.norm.y10_12
                    }  
                    if (this.gender === 'male'||this.gender === 'female') {
                        let g 
                        if (this.gender==='male') {
                            g = 'm'
                        }
                        if(this.gender==='female'){
                            g = 'f'
                        }
                        if (this.days>=4320&&this.days<5475) {
                            norm = parameter.norm[g].y12_15
                        }
                        if (this.days>=5475&&this.days<6570) {
                            norm = parameter.norm[g].y15_18
                        }
                        if (this.days>=6570&&this.days<16425) {
                            norm = parameter.norm[g].y18_45
                        }
                        if (this.days>=16425&&this.days<23725) {
                            norm = parameter.norm[g].y45_65
                        }
                        if (this.days>23725) {
                            norm = parameter.norm[g].y65
                        }
                    }
                }
                else if(parameter.name == 'Концентрация HB в эритроците (MCHC)') {
                    if (this.gender==='male') {
                        norm = parameter.norm.m;
                    }
                    else if(this.gender==='female'){
                        norm = parameter.norm.f
                    }
                    else norm = null
                } 
                // else if (parameter.result === false){
                //     norm = '';
                // }     
                else {
                    if (typeof parameter.norm !== 'undefined') {
                        if (this.days<=30) {
                            norm = parameter.norm.m0_1
                        }
                        if (this.days>30&&this.days<60) {
                            norm = parameter.norm.m_2;
                        } 
                        if (this.days>=60&&this.days<150) {
                            norm = parameter.norm.m3_4
                        }
                        if (this.days>=150&&this.days<210) {
                            norm = parameter.norm.m5_6
                        }
                        if (this.days>=210&&this.days<300) {
                            norm = parameter.norm.m7_9;
                        }
                        if (this.days>=300&&this.days<365) {
                            norm = parameter.norm.m10_12;
                        }
                        if (this.days>=365&&this.days<2190) {
                            norm = parameter.norm.y1_6;
                        }
                        if (this.days>=2190&&this.days<4380) {
                            norm = parameter.norm.y6_12;
                        }
                        if (this.days>=4380&&this.days<=5475) {
                            norm = parameter.norm.y12_15;
                        }
                        if (this.days>5475&&this.gender==='male') {
                            norm = parameter.norm.a15ym;
                        }
                        if (this.days>5475&&this.gender==='female') {
                            norm = parameter.norm.a15yw;
                        }
                    }    
                }
                norm = norm.replace(/,/g, '.');
                if (parameter.isTitle === true){
                    this.params.push({'name':parameter.name,'isTitle':true,'disabled':false});
                } else {
                    this.params.push({'name':parameter.name,'result':null,'resultInput':parameter.resultInput,'unit':parameter.unit,'norm':norm, 'alert':false, 'disabled':true});
                }

            });
            if(this.params) {
                resolve()
            }
        });

        tableRender.then(() => {
            if(!this.firstLoad||force===true) {             
                this.firstLoad = true;
                const regex = /row/ig;
                const regex2 = /col/ig;
                const tempElement = new DOMParser().parseFromString(this.medRecordValue.replaceAll(regex,'tr').replaceAll(regex2,'td'), "text/html")
 
                this.params.forEach(parameter =>{
                    if (tempElement.querySelectorAll('tr').length>0) {
                        tempElement.querySelectorAll('tr').forEach(col=>{
                            if(parameter.name === col.querySelectorAll('td')[0].innerHTML&&col.querySelectorAll('td')[1]) {
                                parameter.result = col.querySelectorAll('td')[1].innerHTML;
                                parameter.resultInput = false;
                                parameter.disabled = false;
                            }
                        })
                    }
                    else {
                        parameter.disabled = false;
                    }
                })
                this.resultCheck();
            }
        })
        
    },
    resultCheck(){
        this.params.forEach(parameter=>{
            if (parameter.result) {  
                parameter.result = parameter.result.replace(/,/g, '.');  
            }
        });  
        this.params.forEach(parameter=>{
            if (parameter.result) {            
                var range = parameter.norm.split('-'); 
                if (Number(parameter.result) < Number(range[0]) || Number(parameter.result) > Number(range[1])) {
                    parameter.alert = true;
                } else {
                    parameter.alert = false;
                }
            }
        });
    },
    addResult(index) {
        const addResult = new Promise((resolve) => {
            this.params[index-1].resultInput = false;
            if(this.params[index]){
                if (this.params[index].isTitle === undefined) {
                    this.params[index].resultInput = true;
                    resolve(index);
                }
                else if (this.params[index].isTitle&&this.params[index].isTitle === true) {
                    this.params[index+1].resultInput = true;
                    resolve(index+1);
                }
            }
        });

        addResult.then((response) => {
            if(this.$refs['cell'+response]) {
                this.$refs['cell'+response][0].focus();
            }
        });    
    },
    addResultUp(index) {
        const addResult = new Promise((resolve) => {
            this.params[index+1].resultInput = false;
            if(this.params[index]){
                if (this.params[index].isTitle === undefined) {
                    this.params[index].resultInput = true;
                    resolve(index);
                }
                else if (this.params[index].isTitle&&this.params[index].isTitle === true) {
                    this.params[index-1].resultInput = true;
                    resolve(index-1);
                }
                
            }    
        });

        addResult.then((response) => {
            if(this.$refs['cell'+response]) {
                this.$refs['cell'+response][0].focus();
            }
        });   
    },
    addResultUnfocus(index){
        this.params[index].resultInput = false;
        this.focusChange = true;

        this.convertation();
    },
    showInput(index) {
        const addResult = new Promise((resolve) => {
            this.params[index].resultInput = true;
            resolve();
        });
        addResult.then(() => {
            this.$refs['cell'+index][0].focus();          
        });  
    },
    convertation() {
        let _this = this
        setTimeout(function(){
            _this.focusChange = true;
            _this.convertTable();
        },100);
    },
    convertTable(){
        const addResult = new Promise((resolve) => {
            function getTextFromHtml(element) {
                let text = '';
                if (element.nodeType === Node.TEXT_NODE) {
                    text += element.textContent.trim();
                }
                element.childNodes.forEach(child => {
                    text += getTextFromHtml(child);
                });

                return text;
            }

            let newTable = '';
            let i = 0

            newTable += '<table>'
                this.$refs['table'].querySelectorAll('tr').forEach(row=>{
                    if (!row.classList.contains('disabled')) {
                        let cols = row.querySelectorAll('td');                   
                        cols.forEach(col=>{
                            if(!col.classList.contains('controlCell')){
                                if(col.hasAttribute('colspan')) {
                                    newTable += `<row><col colspan="${col.getAttribute('colspan')}" type="${col.getAttribute('type')}">${getTextFromHtml(col)}</col></row>`
                                    i = 0;
                                }  
                                else {
                                    if(i===0){
                                        newTable += '<row>'
                                    }
                                    if(col.hasAttribute('type')) {
                                        newTable += `<col ${col.getAttribute('bold') ? ` bold="${col.getAttribute('bold')}"` : ''} ${col.getAttribute('align') ? ` align="${col.getAttribute('align')}"` : ''} type="${col.getAttribute('type')}">${getTextFromHtml(col)}</col>`
                                    }
                                    else {
                                        newTable += `<col ${col.getAttribute('bold') ? ` bold="${col.getAttribute('bold')}"` : ''} ${col.getAttribute('align') ? ` align="${col.getAttribute('align')}"` : ''}>${getTextFromHtml(col)}</col>`
                                    }
                                    

                                    if (i===3){         
                                        newTable += '</row>'
                                        i = 0;
                                    } else {i++;}
                                }
                            }    
                        })
                    }
                })
                
            newTable += '</table>';
            this.editorField.customValue = newTable;

            this.$parent.fieldUpdateSelectCustom(this.editorField);

            this.$parent.fieldRender();  
            resolve();
        });
        addResult.then(() => {
            this.focusChange = false;
        });  
    }
  }
}
</script>

<style scoped lang="scss">
.table {
    width:100%;
	max-width: 900px;
	margin-bottom: 20px;
	border: 1px solid #dddddd;
	border-collapse: collapse; 
    user-select: text !important;
    font-size: 14px;
}
.table th {
	font-weight: bold;
	padding: 2px;
	background: #efefef;
	border: 1px solid #dddddd;
}
.table td {
	border: 1px solid #dddddd;
	padding: 2px;
    text-align: center;
}
.table td:first-child {
    text-align: left;
}
.disabled {
    opacity: .3;
    transition: opacity .3s;
    &:hover {
        opacity: 1;
    }
}
.controlCell {
    cursor: pointer;
}
</style>