<template>
  <div class="d-inline">
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="medRecordEditorField">{{editorField.currentValue}}</div>
      </template>
      <v-sheet class="overflow-y-hidden" width="450">
        <div class="mb-2">
          <v-row no-gutters class="pt-2 px-2 mb-2">
            <v-col>
              <v-btn text small @click="$parent.fieldUpdateRemove(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-close</v-icon>Удалить значение
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text small @click="$parent.fieldUpdateMore(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-plus</v-icon>Дублировать значение
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
        </div>
        <v-combobox
            ref="text"
            full-width
            v-model="codesSearchActive"
            :loading="codesSearchState.isLoading"
            :items="codesSearch"
            :search-input.sync="codesSearchText"
            :placeholder="editorField.currentValue"
            outlined dense hide-details="auto"
            class="ma-2"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content v-if="codesSearchText==null || codesSearchText===''">
                <v-list-item-title>Введите текст для поиска</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title v-if="codesSearchState.isRequest">Ожидание конца ввода...</v-list-item-title>
                <v-list-item-title v-if="codesSearchState.isLoading">Идет поиск...</v-list-item-title>
                <v-list-item-title v-if="codesSearchState.isSuccess">Не найдено по запросу</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{item}">
            <div class="full-width" @click="update(item)">
              <div class="py-1">
                <v-breadcrumbs class="pa-0">
                  <v-breadcrumbs-item class="caption">{{item.value.dir}}</v-breadcrumbs-item>
                  <v-breadcrumbs-divider v-if="item.value.parent!=null && item.value.parent.parent!=null" class="caption px-1">/</v-breadcrumbs-divider>
                  <v-breadcrumbs-item v-if="item.value.parent!=null && item.value.parent.parent!=null" class="caption">
                    <div class="grey--text float-left mr-1 caption font-weight-medium rounded-small">{{item.value.parent.parent.code}}</div>
                    <span class="text-pre-wrap">{{item.value.parent.parent.name}}</span>
                  </v-breadcrumbs-item>
                  <v-breadcrumbs-divider v-if="item.value.parent!=null" class="caption px-1">/</v-breadcrumbs-divider>
                  <v-breadcrumbs-item v-if="item.value.parent!=null" class="caption">
                    <div class="grey--text float-left mr-1 caption font-weight-medium rounded-small">{{item.value.parent.code}}</div>
                    <span>{{item.value.parent.name}}</span>
                  </v-breadcrumbs-item>
                </v-breadcrumbs>
                <div class="pt-1 pb-2">
                  <div class="grey white--text px-1 float-left mr-3 caption font-weight-medium rounded-small">{{item.value.code}}</div>
                  <div class="body-2 font-weight-medium float-left mr-1 mb-2">{{item.value.name}}</div>
                  <v-menu v-if="item.value.additional_info!==''" open-on-hover offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" class="mt-n2 position-relative">
                        <v-icon small>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card max-width="500">
                      <div class="py-2 px-4 body-2" v-html="item.value.additional_info"></div>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:selection></template>
        </v-combobox>
        <div>
          <v-list-item
              v-for="(option, index2) in editorField.options"
              :key="index2"
              @click="$parent.fieldUpdateSelect(editorField, option);isOpen=false">
            <v-list-item-title>
              {{ option.length===0 ? '(Пусто)' : option.value }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-sheet>

    </v-menu>
  </div>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";

export default {
  props:['editorField', 'editorFieldOpen'],
  data: () => ({
    isOpen:false,
    codesSearchActive:false,
    codesSearchState: new State(),
    codesSearch:[],
    codesSearchText:'',
  }),
  watch: {
    codesSearchText() {
      this.codesSearchState.stateRequest();
      this.$tools.throttle(()=>{
        if (this.codesSearchText!=null && this.codesSearchText.length>1 && this.codesSearchText!==this.codesSearchTextLast){
          this.codesSearchTextLast = this.codesSearchText;
          this.codesSearchState.stateLoading();
          axios.get('/api/medRecord/search/icd/', {
            params:{
              q: this.codesSearchText
            }
          }).then((v)=>{
            this.codesSearchState.stateSuccess();
            v.data.forEach((v)=>{
              v['additional_info'] = v['additional_info'].split("\n").join("<br/>")
              let find = false;
              this.codesSearch.forEach((v1)=>{
                if (v1.value.id===v.id)
                  find = true;
              });

              if (!find)
                this.codesSearch.push({
                  text:
                      v['id'] + '|' +
                      v['code'] + '|' +
                      v['name'] + '|' +
                      v['additional_info'] + '|' +
                      v['dir'],
                  value: v
                });
            });
          }).catch((e)=>{
            this.codesSearchState.stateError();
            console.log(e);
          })
        }
      });

    },
    isOpen(){
      setTimeout(()=>{
        if (this.isOpen)
          this.$refs.text.$el.querySelector('input').focus();
      }, 100);
    }
  },
  methods:{
    update(item){
      this.isOpen = false;
      this.editorField.customValue = item.value.name+' ('+item.value.code+')';
      this.$parent.fieldUpdateSelectCustom(this.editorField);
    }
  }
}
</script>