<template>
  <autocomplete-single name="Отделение" :items="arr" :select.sync="localSelect" :loading="api.loading">
    <template v-slot:itemInfo="{item}">
      <div class="caption">
        <v-row no-gutters align-content="center">
          <v-col cols="auto"><v-avatar :color="item.comBranch.color" size="10" class="mr-1" style="margin-top:-3px"/></v-col>
          <v-col>{{item.comBranch.name}}</v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:selectedInfo="{item}">
      <v-avatar :color="item.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/> {{item.comBranch.name}} / {{item.name}}
    </template>
  </autocomplete-single>
</template>

<script>

import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import GraphqlApi from "@/plugins/graphqlApi";

export default {
  components: {AutocompleteSingle},
  props:[
    'select',
  ],
  data:()=>({
    api: new GraphqlApi(),
    arr:[],
    localSelect:null
  }),
  watch:{
    select(){
      this.localSelect = this.select;
    },
    localSelect(){
      this.$emit('update:select', this.localSelect)
    }
  },
  mounted() {
    this.localSelect = this.select;
    this.api.requestSingleModelMultipleDataV1(this.arr, 'ComDiv', '/api/graphql/', `
      query Query{
        ComDiv(sortBy:"name",sortDesc:false){
          id
          name
          comBranch{
            id
            name
            color
          }
        }
      }
    `,null, null, null, true);
  }
}
</script>