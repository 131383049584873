<template>
  <div class="d-inline">
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="medRecordEditorField">{{editorField.currentValue}}</div>
      </template>
      <v-sheet width="480">
        <div class="mb-2">
          <v-row no-gutters class="pt-2 px-2 mb-2 align-center justify-space-between">
            <v-col>
              <v-btn text small @click="$parent.fieldUpdateRemove(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-close</v-icon>Удалить значение
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text small @click="$parent.fieldUpdateMore(editorField);isOpen=false">
                <v-icon dense class="mr-2">mdi-plus</v-icon>Дублировать значение
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <calcs-menu @calc="(v)=>{editorField.customValue=v;$parent.fieldUpdateSelectCustom(editorField);isOpen=false}" :userId="userId" :types="['number']"/>
            </v-col>
          </v-row>
          <v-divider/>
        </div>
        <v-text-field
            ref="text"
            v-model="editorField.customValue"
            placeholder="Введите число"
            @click:append="$parent.fieldUpdateSelectCustom(editorField);isOpen=false"
            @keydown.enter="saveByEnter($event,editorField)"
            append-icon="mdi-check"
            type="number"
            class="medRecordEditorFieldTextField ma-2"
            :value="editorField.option"
            outlined small dense hide-details="auto"
        />
        <div>
          <v-list-item
              v-for="(option, index2) in editorField.options"
              :key="index2"
              @click="$parent.fieldUpdateSelect(editorField, option);isOpen=false">
            <v-list-item-title>
              {{ option.length===0 ? '(Пусто)' : option.value }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import CalcsMenu from "@/views/MedRecord/calcs/CalcsMenu.vue";

export default {
  components:{CalcsMenu},
  props:['editorField', 'editorFieldOpen', 'userId'],
  data: () => ({
    isOpen:false,
  }),
  watch:{
    isOpen(){
      setTimeout(()=>{
        if (this.isOpen)
          this.$refs.text.$el.querySelector('input').focus();
      }, 100);
    }
  },
  methods: {
    saveByEnter(event,editorField) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.$parent.fieldUpdateSelectCustom(editorField);
        this.isOpen=false;
      }
    }
  }
}
</script>

