<template>
  <v-sheet
      class="d-inline-block mt-n3"
  >
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
        max-width="450"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="medRecordEditorField">{{editorField.currentValue}}</div>
      </template>
      <div class="mb-2">
        <v-row no-gutters class="pt-2 px-2 mb-2">
          <v-col>
            <v-btn text small @click="$parent.fieldUpdateRemove(editorField);isOpen=false">
              <v-icon dense class="mr-2">mdi-close</v-icon>Удалить значение
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text small @click="$parent.fieldUpdateMore(editorField);isOpen=false">
              <v-icon dense class="mr-2">mdi-plus</v-icon>Дублировать значение
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
      </div>
      <v-date-picker
          :first-day-of-week="1"
          full-width
          v-model="value"
          @input="update();isOpen = false"
          no-title
      />
      <div>
        <v-list-item
            v-for="(option, index2) in editorField.options"
            :key="index2"
            @click="$parent.fieldUpdateSelect(editorField, option);isOpen=false">
          <v-list-item-title>
            {{ option.length===0 ? '(Пусто)' : option.value }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-menu>
  </v-sheet>
</template>

<script>
import moment from "moment/moment";

export default {
  props:['editorField', 'editorFieldOpen'],
  data: () => ({
    isOpen:false,
    value:'',
    currentDate:moment(new Date()).format('DD.MM.YYYY')
  }),
  mounted(){
    if (this.editorField.currentValue == 'дата') {
      this.editorField.currentValue = this.currentDate
      this.value = moment(new Date()).format('YYYY-MM-DD')
      this.update()
    }
  },
  methods:{
    update(){    
      this.editorField.customValue = moment(this.value).format('DD.MM.YYYY');
      this.$parent.fieldUpdateSelectCustom(this.editorField);
    }
  }
}
</script>
