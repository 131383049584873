<template>
  <div>
    <dialog-view
        :open="open"
        title="Перенести между личными счетами"
        @cancel="$emit('complete')"
        content-full-size
        width="540"
    >
      <template v-slot:content>
        <div class="mx-4 mt-4">
          <user-select title="Кому" :multiple="false" @selected="(v)=>userTo=v" birthDay/>
        </div>
        <v-divider/>
        <div class="ma-4">
          <v-text-field
              v-model="amount"
              label="Сумма"
              outlined dense hide-details="auto" class="big" clearable/>
        </div>
        <v-divider/>
        <div class="ma-4">
          <v-textarea v-model="comment" label="Комментарий к платежу" outlined dense hide-details auto-grow rows="2" clearable/>
        </div>
        <v-divider/>
        <div class="mx-4 my-4">
          <v-btn @click="pay()" :loading="payState.isLoading" block depressed color="primary" :disabled="$tools.isEmpty(amount)">Перенести</v-btn>
        </div>
      </template>
    </dialog-view>
  </div>

</template>

<script>
import DialogView from "@/components/DialogView";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import UserSelect from "@/views/User/components/UserSelect.vue";
export default {
  components: {UserSelect, DialogView},
  props:['open', 'userId'],
  data: () => ({
    userTo:null,
    amount:null,
    comment:null,

    payState: new State(),
  }),
  watch:{
    'amount'(str) {
      this.amount = this.$tools.priceFormatter(str);
    },
  },
  methods: {
    pay(){
      this.payState.stateLoading();
      axios.post(Api.host+'/pay/accountBalanceTransfer/', {
        userFromId:this.userId,
        userToId:this.userTo==null ? null : this.userTo.id,
        amount:this.amount,
        comment:this.comment,
      }).then(()=>{
        this.$store.commit('alertSuccess', 'Успешный перенос');
        this.payState.stateSuccess();
        this.$emit('complete');
      }).catch(e =>{
        this.$store.commit('alertError', e.response.data.message);
        this.payState.stateError();
      });
    }
  }
}
</script>
