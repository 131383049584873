<template>
  <div class="d-inline">
    <table class="medRecordFieldTable my-3" ref="table">
      <med-record-update-table-row v-for="(row, index) in rows" :key="'row_'+index" :body="row" :index="index" @updated="rowUpdated" :medRecordTemplateFieldValues="medRecordTemplateFieldValues"/>
    </table>
  </div>
</template>

<script>
import MedRecordUpdateTableRow from "@/views/MedRecord/MedRecordUpdateTableRow";
export default {
  components: {MedRecordUpdateTableRow},
  props:['editorField', 'medRecordTemplateFieldValues', 'index'],
  data: () => ({
    rows:[],
    previousState:[]
  }),
  watch: {
    editorField: {
      immediate: true,
      handler () {
        this.load();
      }
    }
  },
  methods:{
    load(){
      this.rows = [];
      let bodyData = this.editorField.table.replace('<table>', '').replace('</table>', '');
      bodyData.replaceAll(/<row>[\s\S]*?<\/row>/g, (match)=>{
        this.rows.push(match);
        return null;
      });

      //save previus table state
      // if(typeof this.$refs.table !== 'undefined') {
      //   this.previousState = [];  
      //   this.$refs.table.querySelectorAll('[role="button"]').forEach(f=>{
      //     let value = f.querySelector('[role="button"] span').innerText;
      //     this.previousState.push(value);
      //   });
      // }
    },
    rowUpdated(d){
      this.rows[d.index] = d.body;
      let bodyTable = '';
      this.rows.forEach((v)=>{
        bodyTable +=v;
      });

      this.editorField.table = '<table>'+bodyTable+'</table>';
      this.$parent.fieldUpdatesToMedRecordValueValue();
      
      //focus on next field
      // if(typeof this.$refs.table !=='undefined'){
      //   setTimeout(()=>{
      //     this.focusOnNextField();
      //   },50);
      // }
    },
    // focusOnNextField(){
    //     let fields = this.$refs.table.querySelectorAll('[role="button"]');
    //     let indexes = [];
    //     let newState = [];
    //     fields.forEach((f,index)=>{
    //       let value = f.querySelector('[role="button"] span').innerText;
    //       let oldValue = this.previousState[index];
    //       newState.push(value);

    //       if (value!==oldValue) {
    //         indexes.push(index);
    //       }
    //     });

    //     if (indexes.length<2){
    //       if (typeof fields[indexes[0]+1] !== 'undefined'){
    //           this.previousState[indexes[0]] = fields[indexes[0]].querySelector('[role="button"] span').innerText;
    //           fields[indexes[0]+1].click();
    //       }            
    //     } else {
    //       this.previousState = newState;
    //     }
    // }
  }
}
</script>

<style lang="scss" scoped>
table {
  width:100%;
  border-collapse: collapse;
  user-select: text !important;
  margin-bottom: -1.5rem;
}
</style>