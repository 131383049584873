<template>
  <div class="pa-6">
    <v-card elevation="2">
      <div class="px-2 pt-4">
        <v-row>
          <v-col>
            <v-autocomplete
                v-model="parents"
                :items="users"
                :search-input.sync="parentsSearchText"
                label="Родители"
                outlined
                dense
                hide-details="auto"
                multiple
                class="v-autocomplete-circle"
                item-text="name"
                item-value="id"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{parentsSearchText==null || parentsSearchText.length===0 ? 'Введите текст для поиска' : 'Не найдено пользователя по запросу "'+parentsSearchText+'"'}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="py-2">
                      #{{item.id}}
                      {{item.lastName}}
                      {{item.firstName}}
                      {{item.secondName}}
                      {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{item}">
                <div class="full-width">
                  <v-chip class="mt-2" close @click:close="parentRemove(item)" small>
                    #{{item.id}}
                    {{item.lastName}}
                    {{item.firstName}}
                    {{item.secondName}}
                    {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                  </v-chip>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
                v-model="childs"
                :items="users"
                :search-input.sync="childsSearchText"
                label="Дети"
                outlined
                dense
                hide-details="auto"
                multiple
                class="v-autocomplete-circle"
                item-text="name"
                item-value="id"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{childsSearchText==null || childsSearchText.length===0 ? 'Введите текст для поиска' : 'Не найдено пользователя по запросу "'+childsSearchText+'"'}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="py-2">
                      #{{item.id}}
                      {{item.lastName}}
                      {{item.firstName}}
                      {{item.secondName}}
                      {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{item}">
                <div class="full-width">
                  <v-chip class="mt-2" close @click:close="childRemove(item)" small>
                    #{{item.id}}
                    {{item.lastName}}
                    {{item.firstName}}
                    {{item.secondName}}
                    {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                  </v-chip>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                v-model="maried"
                :items="users"
                :search-input.sync="mariedSearchText"
                label="Супруг"
                outlined
                dense
                hide-details="auto"
                class="v-autocomplete-circle"
                item-text="name"
                item-value="id"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{mariedSearchText==null || mariedSearchText.length===0 ? 'Введите текст для поиска' : 'Не найдено пользователя по запросу "'+mariedSearchText+'"'}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="py-2">
                      #{{item.id}}
                      {{item.lastName}}
                      {{item.firstName}}
                      {{item.secondName}}
                      {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{item}">
                <div class="full-width">
                  <v-chip class="mt-2" close @click:close="maried = null" small>
                    #{{item.id}}
                    {{item.lastName}}
                    {{item.firstName}}
                    {{item.secondName}}
                    {{'('+$tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY')+')'}}
                  </v-chip>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" depressed @click="familySaveAction" :loading="familySaveState.isLoading">Сохранить</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-4"/>
      <div class="pl-3 pt-2 body-1 font-weight-medium">Структура</div>
      <div v-if="familyLoadState.isSuccess" class="px-2 body-2 pb-4">
        <client-family-parent-tree :parents="family.parents" :index="0"/>
        <div class="mt-4" no-gutters>
          <div class="font-weight-medium text-center">
            {{family.user.lastName}} {{family.user.firstName}} {{family.user.secondName}}
          </div>
          <div v-if="family.user.mariedUser!==null" class="text-center">
            <v-chip small color="green" dark class="my-2">Супруги</v-chip>
            <div>
              <a :href="'../../'+family.user.mariedUser.id+'/family/'">
                {{family.user.mariedUser.lastName}} {{family.user.mariedUser.firstName}} {{family.user.mariedUser.secondName}}
              </a>
            </div>
          </div>
        </div>
        <client-family-child-tree :childs="family.childs" :index="0"/>
      </div>
      <div v-else class="mx-2 mb-4 py-8 text-center">
        <v-progress-circular color="primary" indeterminate/>
      </div>
    </v-card>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import ClientFamilyParentTree from "@/views/User/Client/ClientFamilyParentTree";
import ClientFamilyChildTree from "@/views/User/Client/ClientFamilyChildTree";

export default {
  components: {ClientFamilyChildTree, ClientFamilyParentTree},
  data: () => ({
    family:[],

    parents:[],
    parentsSearchText:'',
    childs:[],
    childsSearchText:'',
    maried:null,
    mariedSearchText:'',
    familyLoadState: new State(),
    familySaveState: new State(),

    users:[],
    usersState: new State(),
  }),
  mounted() {
    this.familyLoad();
  },
  watch:{
    parentsSearchText(){
      this.getUsers(this.parentsSearchText);
    }
  },
  methods:{
    getUsers(str){
      this.usersState.stateLoading();
      Api.service.user.list({q:str}, 1, 1000000,(v)=>{
        this.usersState.stateSuccess();
        console.log(v.data);
        v.data.forEach((v1)=>{v1.name = v1.id+'|'+v1.lastName+'|'+v1.firstName+'|'+v1.secondName});
        this.users = v.data.filter((element) => { return element.id != this.$router.currentRoute.params.id } )
      }, ()=>{
        this.usersState.stateError();
      });
    },
    parentRemove(item){
      this.parents = this.parents.filter((v)=>{
        return v.id!==item.id
      });
      this.parents = this.parents.filter((v)=>{
        return v!==item.id
      });
    },
    childRemove(item){
      this.childs = this.childs.filter((v)=>{
        return v.id!==item.id
      });
      this.childs = this.childs.filter((v)=>{
        return v!==item.id
      });
    },
    familyLoad(){
      this.familyLoadState.stateLoading();
      Api.service.user.family(this.$router.currentRoute.params.id, (v)=>{
        this.familyLoadState.stateSuccess();
        v.parents.forEach((v1)=>{v1.name = v1.id+'|'+v1.lastName+'|'+v1.firstName+'|'+v1.secondName;});
        v.childs.forEach((v1)=>{v1.name = v1.id+'|'+v1.lastName+'|'+v1.firstName+'|'+v1.secondName;});

        this.family = v;
        this.parents = v.parents;
        this.maried = v.user.mariedUser;
        this.childs = v.childs;
      }, ()=>{
        this.familyLoadState.stateError();
      });
    },
    familySaveAction(){
      this.familySaveState.stateLoading();
      let parents = [];
      this.parents.forEach((v)=>{
          parents.push(typeof v==='object' ? v.id : v);
      });
      let childs = [];
      this.childs.forEach((v)=>{
        childs.push(typeof v==='object' ? v.id : v);
      });
      let mariedId = null;
      if (this.maried!==null)
        mariedId = typeof this.maried==='object' ? this.maried.id : this.maried;
      Api.service.user.familySave(this.$router.currentRoute.params.id, parents, mariedId, childs,()=>{
        this.familySaveState.stateSuccess();
        this.familyLoad();
      }, ()=>{
        this.familySaveState.stateError();
      });
    },
  }
}
</script>
