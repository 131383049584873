<template>
  <layout-view>
    <template v-slot:content> 
      <v-btn
        @click="$emit('visit-plans-load');"
        color="grey lighten-3" depressed small class="mt-5 ml-5 mb-5"
        :loading="visitPlanLoadState.isLoading||loadState.isLoading"
    >
      Обновить список <v-icon small>mdi-refresh</v-icon>
    </v-btn>
      <v-list class="pa-0" v-if="loadState.isSuccess">
         <v-list-item
             v-for="(visitPlan, k) in visitPlans" :key="'visitPlan'+k"
             @click="visitPlanState.modalOpen(visitPlan);visitPlanId = visitPlan.id">
           <v-list-item-content>
             <v-list-item-title>
               <div class="font-weight-medium mb-1">
                 {{visitPlan.user.lastName=== null ? '': visitPlan.user.lastName}}
                 {{visitPlan.user.firstName=== null ? '': visitPlan.user.firstName}}
                 {{visitPlan.user.secondName=== null ? '': visitPlan.user.secondName}}
               </div>
               <div class="caption mb-1">К специалисту{{visitPlan.isLiveList ? '': ' '+$tools.date(visitPlan.begin)}}:</div>
               <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                 <v-col cols="auto">
                   <v-avatar class="user" size="32">
                     <img :src="visitPlan.userSpec.user.imageUrl" v-if="visitPlan.userSpec.user.imageUrl" alt="">
                   </v-avatar>
                 </v-col>
                 <v-col class="pl-2">
                   <div class="font-weight-medium">
                     {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                     {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                     {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                   </div>
                   <div class="caption text-wrap">
                     {{visitPlan.userSpec.spec.name}}
                     <v-icon small>mdi-chevron-left</v-icon>
                     {{visitPlan.userSpec.comDiv.comBranch.name}}
                     <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                       <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                       {{visitPlan.userSpec.commentAdmin}}
                     </div>
                   </div>
                 </v-col>
               </v-row>
               <div v-else class="font-weight-medium">
                 {{visitPlan.spec.name}}
               </div>
               <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                 <div class="caption">Комментарий:</div>
                 <div class="caption">{{visitPlan.comment}}</div>
               </div>
             </v-list-item-title>
           </v-list-item-content>
         </v-list-item>
       </v-list>
       
       <visit-plan
          :state="visitPlanState"
          :users="users"
          :specs="specs"
          @cancel="visitPlanState.modalClose()"
          @createdUpdatedDeleted="visitPlanState.modalClose();$emit('visit-plans-load')"
          @visitCreate="visitCreate();visitPlanState.modalClose();$emit('visit-plans-load')"
      />
    </template>
  </layout-view>
</template>

<script>
import Api from "@/Api";
import axios from "axios";
import State from "@/plugins/state";
import LayoutView from "@/components/LayoutView.vue";
import VisitPlan from "@/views/Visit/VisitPlan";

export default {
  components: {VisitPlan, LayoutView},
  props: ['visitPlans','visitPlanLoadState'],
  data: () => ({
    visitPlanState: new State(),
    loadState: new State(),
    visitPlanId:null,
    specs: [],
    users: [],
  }),
  mounted(){
    this.load();
  },
  methods:{
    load(){
        this.loadState.stateLoading();
        axios.all([
          this.specs.length>0 ? null : axios.get(Api.host+'/spec/'),
          this.users.length>0 ? null : axios.get(Api.host+'/user/?type=spec&onPage=100000'),
        ]).then(axios.spread((dataSpecs, dataUsers) => {
          //specs
          if(dataSpecs!==null) this.specs = dataSpecs.data;
          //users
          if(dataUsers!==null) this.users = dataUsers.data.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
          this.users.forEach(v=>{
            v.name = v.lastName+' '+v.firstName+' '+v.secondName;
          });

          this.loadState.stateSuccess();
        }))
        .catch(e =>{
          console.log(e);
          this.loadState.stateError();
        });
    },
    visitCreate() {
      window.open('/panel/visit?userId='+this.$route.params.id+'&visitPlanId='+this.visitPlanId+'&type=visitPlan', '_blank');
    }
  },
}
</script>
