<template>
  <div>
    <portal to="back">
      <v-btn icon @click="$router.push('../../'+$router.currentRoute.params.type+'/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <portal to="title">
      <v-progress-circular v-if="title==null" indeterminate size="20" width="3" class="mt-n1"/>
      <span>{{title}} ({{userType}})</span>
    </portal>
    <portal to="tabs">
      <v-tabs v-if="$router.currentRoute.params.type==='client'" v-model="tabSelected" background-color="transparent">
        <v-tab><v-icon class="mr-2">mdi-card-account-details</v-icon>Профиль</v-tab>
        <v-tab><v-icon class="mr-2">mdi-calendar</v-icon>Визиты</v-tab>
        <v-tab><v-icon class="mr-2">mdi-calendar-question</v-icon>Лист ожидания ({{ visitPlansAmount }})</v-tab>
        <v-tab><v-icon class="mr-2">mdi-cash</v-icon>Личный счет и платежи</v-tab>
        <v-tab><v-icon class="mr-2">mdi-text-box</v-icon>Планы лечения и услуги</v-tab>
        <v-tab><v-icon class="mr-2">mdi-clipboard-text</v-icon>Мед. карта</v-tab>
        <v-tab><v-icon class="mr-2">mdi-file-document</v-icon>Документы</v-tab>
        <v-tab><v-icon class="mr-2">mdi-account-group</v-icon>Семья</v-tab>
        <v-tab><v-icon class="mr-2">mdi-bell</v-icon>Оповещения</v-tab>
      </v-tabs>
      <v-tabs v-if="$router.currentRoute.params.type==='spec'" v-model="tabSelected" background-color="transparent">
        <v-tab><v-icon class="mr-2">mdi-card-account-details</v-icon>Профиль</v-tab>
        <v-tab><v-icon class="mr-2">mdi-doctor</v-icon>Специализации</v-tab>
        <v-tab><v-icon class="mr-2">mdi-currency-rub</v-icon>Услуги</v-tab>
        <v-tab><v-icon class="mr-2">mdi-account-cash</v-icon>Расчёт Зарплаты</v-tab>
        <v-tab><v-icon class="mr-2">mdi-bell</v-icon>Оповещения</v-tab>
      </v-tabs>
      <v-tabs v-if="$router.currentRoute.params.type==='admin'" v-model="tabSelected" background-color="transparent">
        <v-tab><v-icon class="mr-2">mdi-card-account-details</v-icon>Профиль</v-tab>
        <v-tab><v-icon class="mr-2">mdi-doctor</v-icon>Специализации</v-tab>
        <v-tab><v-icon class="mr-2">mdi-account-cash</v-icon>Расчёт Зарплаты</v-tab>
        <v-tab><v-icon class="mr-2">mdi-bell</v-icon>Оповещения</v-tab>
      </v-tabs>
    </portal>
    <div v-if="$router.currentRoute.params.type==='client'">
      <UserProfile v-if="tabSelected===0"/>
      <ClientVisits v-if="tabSelected===1" :visitsUnpayed.sync="visitsUnpayed"/>
      <ClientVisitPlans v-if="tabSelected===2" :visitPlans="visitPlans" :visitPlanLoadState="visitPlanLoadState" @visit-plans-load="visitPlansLoad()" />
      <ClientPays v-if="tabSelected===3" @visitsUnpayed="visitsUnpayed=true"/>
      <ClientMedPlansVisitServices v-if="tabSelected===4"/>
      <ClientMedRecords v-if="tabSelected===5" :userName="title"/>
      <ClientMedias v-if="tabSelected===6" :userName="title"/>
      <ClientFamily v-if="tabSelected===7"/>
      <UserNotifs v-if="tabSelected===8"/>
    </div>
    <div v-if="$router.currentRoute.params.type==='spec'">
      <UserProfile v-if="tabSelected===0"/>
      <UserSpec v-if="tabSelected===1"/>
      <UserServices v-if="tabSelected===2"/>
      <UserSalary v-if="tabSelected===3"/>
      <UserNotifs v-if="tabSelected===4"/>
    </div>
    <div v-if="$router.currentRoute.params.type==='admin'">
      <UserProfile v-if="tabSelected===0"/>
      <UserSpec v-if="tabSelected===1"/>
      <UserSalary v-if="tabSelected===2"/>
      <UserNotifs v-if="tabSelected===3"/>
    </div>
  </div>
</template>

<script>
import ClientVisits from "@/views/User/Client/ClientVisits";
import ClientVisitPlans from "@/views/User/Client/ClientVisitPlans";
import ClientPays from "@/views/User/Client/ClientPays";
import ClientMedPlansVisitServices from "@/views/User/Client/ClientMedPlansVisitServices";
import ClientMedRecords from "@/views/User/Client/ClientMedRecords";
import ClientMedias from "@/views/User/Client/ClientMedias";
import ClientFamily from "@/views/User/Client/ClientFamily";
import UserProfile from "@/views/User/UserProfile";
import UserServices from "@/views/User/UserServices";
import axios from "axios";
import UserSpec from "@/views/User/UserSpec";
import UserSalary from "@/views/User/UserSalary";
import Api from "@/Api";
import State from "@/plugins/state";
import UserNotifs from "@/views/User/UserNotifs.vue";

export default {
  components: {
    UserNotifs,
    UserSpec,
    UserProfile,
    ClientFamily,
    ClientMedias,
    ClientMedRecords,
    ClientMedPlansVisitServices,
    ClientPays,
    ClientVisits,
    UserServices,
    UserSalary,
    ClientVisitPlans
  },
  data:()=>({
    tabSelected:null,
    title:null,
    visitsUnpayed:false,
    userType:null,
    
    visitPlanLoadState: new State(),
    visitPlans:[],
    visitPlansAmount: 0,
  }),
  watch:{
    visitsUnpayed(){
      if(this.visitsUnpayed)
        this.tabSelected = 1;
    }
  },
  mounted(){
    axios.get('/api/user/'+this.$route.params.id+'/')
      .then((r)=> {
        this.title = '';
        this.title += r.data.lastName != null ? r.data.lastName + ' ' : '';
        this.title += r.data.firstName != null ? r.data.firstName + ' ' : '';
        this.title += r.data.secondName != null ? r.data.secondName + ' ' : '';

        if(this.$router.currentRoute.params.type==='client') {
          this.userType = 'Клиент'
          this.visitPlansLoad();
        }

        if(this.$router.currentRoute.params.type==='spec') {
          this.userType = 'Специалист'
        }

        if(this.$router.currentRoute.params.type==='admin') {
          this.userType = 'Администратор'
        }

        if(window.location.hash==='#media_medCard')
          this.tabSelected = 5;

        if(window.location.hash==='#media_documents')
          this.tabSelected = 6;
      })
      .catch((error)=> {
        console.log(error)
      });
  },
  methods:{
    visitPlansLoad(){
      this.visitPlanLoadState.stateLoading();
      axios.get(Api.host+'/visitPlan/?liveList=0&userId='+this.$route.params.id)
      .then(dataVisitPlans => {
        //visitPlans
        this.visitPlans = dataVisitPlans.data.data;
        this.visitPlansAmount = dataVisitPlans.data.total
        this.visitPlanLoadState.stateSuccess();
      })
      .catch(e =>{
        console.log(e);
        this.visitPlanLoadState.stateError();
      });
    },
  },
  
}
</script>