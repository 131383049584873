<template>
  <div>
      <v-menu
        v-model="calcMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab depressed x-small  
            class="ml-2"                      
            v-bind="attrs"
            v-on="on">
            <v-icon dense >mdi-calculator-variant-outline</v-icon>
          </v-btn>
        </template>
        
        <v-card>
          <v-list dense>
            <v-subheader>Калькуляторы:</v-subheader>
            <v-list-item-group
              v-model="calcSelected"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in calculators"
                :key="i"
                @click="calcMenu = false"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <dialog-editor
        :name="calcSelected === 0 ? 'Индекс массы тела' : calcSelected === 1 ? 'Срок беременности' : 'Калькулятор'"
        :item.sync="calcSelected"
        width="600"
        @submit="save"
    >
      <template v-slot:default="{localItem}">
        <imt-calc v-if="localItem===0" @calc="(v)=>{calcResult = v}"/>
        <pregnancy-term v-if="localItem===1" @calc="(v)=>{calcResult = v}" :userId="userId"/>
      </template>
    </dialog-editor>
  </div>
</template>

<script>
import DialogEditor from "@/componentsV2/base/DialogEditor.vue";
import ImtCalc from "@/views/MedRecord/calcs/ImtCalc.vue";
import PregnancyTerm from "@/views/MedRecord/calcs/PregnancyTerm.vue";

export default {
  components:{DialogEditor, ImtCalc, PregnancyTerm},
  props: ['userId','types'],
  data: () => ({
    calcMenu:false,
    calcSelected:null,
    calculators:[
      { text: 'ИМТ', icon: 'mdi-weight-kilogram', type:'number' },
      { text: 'Срок беременности', icon: 'mdi-human-pregnant', type:'text' },
    ],
    calcResult: null,
  }),
  mounted(){
    this.calculators = this.calculators.filter(item=>this.types.indexOf(item.type) !== -1);
  },
  methods:{
    save() {
      if(this.calcResult&&this.calcResult!=='') {
        this.$emit('calc',this.calcResult);
        this.calcSelected=null;
        this.calcResult=null;
      } else {
        this.$store.commit('alertError','некорректные значения');
      }
    }
  }
}
</script>