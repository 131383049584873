<template>
  <layout-view>
    <template v-slot:content="{height}">
      <graphql-table-view
        title="Список"
        query="
          query Query($page: Int, $onPage: Int, $user_id: Int){
            UserService(page: $page, onPage: $onPage, user_id: $user_id){
              id
              duration
              priceTotal
              service{
                id
                name
                comment
                des
                code
                priceExec
                priceTotal
                priceExpend
                isPriceLock
                duration
                sort
                isid
                isPriceLock
                isShowForClient
                isVisitCreateForClient
                serviceCat{
                  id
                  name
                  isProgram
                  serviceCat{
                    id
                    name
                    isProgram
                    serviceCat{
                      id
                      name
                      isProgram
                      serviceCat{
                        id
                        name
                        isProgram
                        serviceCat{
                          id
                          name
                          isProgram
                          serviceCat{
                            id
                            name
                            isProgram
                            serviceCat{
                              id
                              name
                              isProgram
                              serviceCat{
                                id
                                name
                                isProgram
                                serviceCat{
                                  id
                                  name
                                  isProgram
                                  serviceCat{
                                    id
                                    name
                                    isProgram
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        "
        :queryVars="{
          user_id:parseInt($router.currentRoute.params.id),
        }"
        queryMainEntity="UserService"
        :headers="[
          { text: 'ID', value: 'id', class:'text-no-wrap'},
          { text: 'Услуга', value: 'name', class:'text-no-wrap', sortable: false },
          { text: 'Длительность стандартная', value: 'duration', class:'text-no-wrap', sortable: false},
          { text: 'Цена итоговая стандартная', value: 'priceTotal', class:'text-no-wrap', sortable: false},
          { text: 'Длительность', value: 'duration', class:'text-no-wrap', sortable: false},
          { text: 'Цена итоговая', value: 'priceTotal', class:'text-no-wrap', sortable: false}
        ]"
        :height="height"
        tableClass="userServices"
        ref="userServices"
      >
        <template v-slot:actions>
          <v-btn small depressed @click="createState.modalOpen({userServicesDuration:null,userServicesPriceTotal:null})">Добавить</v-btn>
        </template>
        <template v-slot:item="{item}">
          <tr @click="updateState.modalOpen($tools.copy(item))">
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap py-1">
              <v-breadcrumbs class="py-1 px-0 text-no-wrap">
                <v-breadcrumbs-item v-if="$tools.notNull(item.service, 'serviceCat.serviceCat.serviceCat.serviceCat')" class="caption">{{item.service.serviceCat.serviceCat.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider v-if="$tools.notNull(item.service, 'serviceCat.serviceCat.serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

                <v-breadcrumbs-item v-if="$tools.notNull(item.service, 'serviceCat.serviceCat.serviceCat')" class="caption">{{item.service.serviceCat.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider v-if="$tools.notNull(item.service, 'serviceCat.serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

                <v-breadcrumbs-item v-if="$tools.notNull(item.service, 'serviceCat.serviceCat')" class="caption">{{item.service.serviceCat.serviceCat.name}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider v-if="$tools.notNull(item.service, 'serviceCat.serviceCat')" class="caption px-1">/</v-breadcrumbs-divider>

                <v-breadcrumbs-item v-if="$tools.notNull(item.service, 'serviceCat')" class="caption">{{item.service.serviceCat.name}}</v-breadcrumbs-item>
              </v-breadcrumbs>
              <div class="mb-1">{{item.service.name}}</div>
              <service-code-view :code="item.service.code"/>
            </td>
            <td class="text-no-wrap text-right">{{item.service.duration}} мин.</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.service.priceTotal)}}</td>
            <td class="text-no-wrap text-right">{{item.duration}} мин.</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.priceTotal)}}</td>
          </tr>
        </template>
      </graphql-table-view>
    </template>
    <template v-slot:footer>
      <dialog-view
          :open="createState.isModalOpen"
          @cancel="createState.modalClose()"
          title="Добавить/изменить привязку"
      >
        <template v-slot:content v-if="createState.modalData!==null">
          <!-- <service-select ref="servicesEl" @selected="servicesSelectAction" /> -->

          <services-select :selectedServices.sync="servicesSelect"/>
          <!-- <v-row class="mt-n2 mb-2">
            <v-col cols="12">
              <v-text-field label="Длительность в минутах" v-model="createState.modalData.userServicesDuration" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Цена итоговая" v-model="createState.modalData.userServicesPriceTotal" type="number" outlined dense hide-details="auto"/>
            </v-col>
          </v-row> -->
        </template>
        <template v-slot:actions>
          <v-btn text color="primary" @click="createAction()" :loading="createState.isLoading">Добавить</v-btn>
        </template>
      </dialog-view>
      <dialog-view
          :open="updateState.isModalOpen"
          @cancel="updateState.modalClose()"
          title="Изменить привязку"
      >
        <template v-slot:content v-if="updateState.modalData!==null">
          <v-row class="mt-n2">
            <v-col cols="12">
              {{updateState.modalData.service.name}}
            </v-col>
            <v-col cols="12">
              <v-text-field label="Длительность в минутах" v-model="updateState.modalData.duration" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Цена итоговая" v-model="updateState.modalData.priceTotal" type="number" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-btn depressed block @click="serviceCreateUpdateState.modalOpen(updateState.modalData.service)" class="mr-2">Открыть услугу</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <dialog-view
              title="Удалить привязку к услуге?"
              acceptName="Удалить"
              @acceptAction="deleteUserService(updateState.modalData)"
              :loading="deleteState.isLoading"
              :open="!deleteState.isSuccess"
              class="d-inline-block"
          >
            <template v-slot:activator>
              <v-btn text color="error" class="mr-2">Удалить</v-btn>
            </template>
          </dialog-view>
          <v-btn text color="primary" @click="updateUserService(updateState.modalData)" :loading="updateState.isLoading">Изменить</v-btn>
        </template>
      </dialog-view>
      <service :state="serviceCreateUpdateState" @createdUpdatedDeleted="serviceCreatedUpdatedDeleted" disableDelete/>
    </template>
  </layout-view>
</template>

<script>
import GraphqlTableView from "@/components/GraphqlTableView.vue";
import LayoutView from "@/components/LayoutView.vue";
import ServiceCodeView from "@/views/Service/ServiceCodeView.vue";
import Service from "@/views/Service/Service.vue";
import State from "@/plugins/state";
import DialogView from "@/components/DialogView.vue";
//import ServiceSelect from "@/views/Service/components/ServiceSelect.vue";
import ServicesSelect from "@/components/ServicesSelect.vue";
import Api from "@/Api";
import axios from "axios";

export default {
  components: {
    //ServiceSelect, 
    DialogView, 
    Service, 
    ServiceCodeView, 
    LayoutView, 
    GraphqlTableView, 
    ServicesSelect},
  data:()=>({
    serviceCreateUpdateState: new State(),
    createState: new State(),
    deleteState: new State(),
    updateState: new State(),
    servicesSelect:[],
  }),
  methods:{
    serviceCreatedUpdatedDeleted(){
      this.$refs.userServices.load(true);
    },
    servicesSelectAction(v){
      this.servicesSelect = v;
    },
    createAction(){
      let arr = [];
      this.servicesSelect.forEach((v)=>{
        arr.push(v.id);
      });

      this.createState.stateLoading();
      axios.post(Api.host+'/service/user/', {
        userId:this.$router.currentRoute.params.id,
        services:arr,
        // duration:this.createState.modalData.userServicesDuration,
        // priceTotal:this.createState.modalData.userServicesPriceTotal
      })
          .then((r) =>{
            this.userServices = r.data;
            this.createState.stateSuccess();
            this.createState.modalClose();
            // this.$refs.servicesEl.selectedClear();
            this.$refs.userServices.load(true);
          })
          .catch(e =>{
            Api.responseError(e)
            this.createState.stateError();
          });
    },
    deleteUserService(userService){
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/userService/'+userService.id+'/')
          .then(() =>{
            this.deleteState.stateSuccess();
            this.updateState.modalClose();
            this.$refs.userServices.load(true);
          })
          .catch(e =>{
            Api.responseError(e)
            this.deleteState.stateError();
          });
    },
    updateUserService(v){
      this.updateState.stateLoading();
      axios.put(Api.host+'/userService/'+v.id+'/', {
        duration:this.updateState.modalData.duration,
        priceTotal:this.updateState.modalData.priceTotal,
      })
          .then(() =>{
            this.updateState.modalClose();
            this.updateState.stateSuccess();
            this.$refs.userServices.load(true);
          })
          .catch(e =>{
            Api.responseError(e)
            this.updateState.stateError();
          });
    }
  }
};
</script>

<style lang="scss">
.userServices tr{
  th, td {
    width: 100px !important;
    white-space: nowrap !important;
  }
  th:nth-child(2), td:nth-child(2) {
    width: 100% !important;
  }
}
</style>

