<template>
  <table v-if="childs.length>0" class="full-width">
    <tr>
      <td v-for="child in childs" :key="child.id" class="text-center">
        <div class="mt-4">
          <a :href="'../../'+child.id+'/family/'">
            {{child.lastName}} {{child.firstName}} {{child.secondName}}
          </a>
          <span v-if="index<=1" class="pl-2">
            {{index===0 ? child.userProfile.gender==='male' ? '(Сын)' : '(Дочь)' : ''}}
            {{index===1 ? child.userProfile.gender==='male' ? '(Внук)' : '(Внучка)' : ''}}
          </span>
        </div>
        <client-family-child-tree :childs="child.childs" :index="index+1"/>
      </td>
    </tr>
  </table>
</template>

<script>
import ClientFamilyChildTree from "@/views/User/Client/ClientFamilyChildTree";

export default {
  name:'ClientFamilyChildTree',
  components: {ClientFamilyChildTree},
  props:['childs', 'index']
}
</script>
