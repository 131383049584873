<template>
  <layout-view
      navTitle="Фильтры"
      :navLoading="statusesState.isLoading"
  >
    <template v-slot:nav>
      <div class="px-4">
        <label-view title="Статус" v-if="statuses.length>0">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="status in statuses" :key="status.id"
                  @click="filters.statuses.filter((v)=>v===status.id).length===0 ? filters.statuses.push(status.id) : filters.statuses = filters.statuses.filter((v)=>v!==status.id); load()"
                  class="mb-0"
              >
                <v-checkbox :value="filters.statuses.filter((v)=>v===status.id).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <visit-status :status="status" small filter/>
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>
        <v-switch
            class="pl-2"
            v-model="remain"
            inset dense
        >
          <template v-slot:label>
            <div class="body-2 grey--text text--darken-3">Не оплачено</div>
          </template>
        </v-switch>
        <v-text-field label="ID" v-model="filters.id" @keyup="load" class="mb-2" outlined dense hide-details="auto"/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <table-view
        :url="url"
        title="Список визитов"
        :headers="[
                { text: 'ID', value: 'id', class:'text-no-wrap', sortable:false},
                { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false},
                { text: 'Специалист', value: 'userSpec', class:'text-no-wrap', sortable:false},
                { text: 'Начислено',  class:'text-no-wrap', sortable:false},
                { text: 'Отменено',  class:'text-no-wrap', sortable:false},
                { text: 'Оплачено',  class:'text-no-wrap', sortable:false},
                { text: 'Осталось',  class:'text-no-wrap', sortable:false},
                { text: 'Дата визита', value: 'begin', class:'text-no-wrap', width: '100%', sortable:false},
              ]"
        tableClass="tableStatused"
        ref="visits"
        :height="height"
      >
        <template v-slot:item="{ item }">
          <tr @click="visitId=item.id" v-if="statuses.length>0">
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">
              <visit-status :status="$tools.findById(statuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/>
            </td>
            <td class="text-no-wrap position-relative">
              <div class="position-absolute mt-2">
                <v-avatar class="user" size="32">
                  <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl">
                </v-avatar>
              </div>
              <div class="ml-10 mt-1">
                {{item.userSpec.user.lastName != null ? item.userSpec.user.lastName + ' ' : ''}}
                {{item.userSpec.user.firstName != null ? item.userSpec.user.firstName + ' ' : ''}}
                {{item.userSpec.user.secondName != null ? item.userSpec.user.secondName + ' ' : ''}}
              </div>
              <div class="ml-10 caption mt-n1 mb-1">
                {{item.userSpec.spec.name}}
              </div>
            </td>
            <td class="text-no-wrap text-right">{{item.paySummary.total.toFixed(2)}} Р</td>
            <td class="text-no-wrap text-right">{{item.paySummary.cancel.toFixed(2)}} Р</td>
            <td class="text-no-wrap text-right">{{item.paySummary.success.toFixed(2)}} Р</td>
            <td class="text-no-wrap text-right">{{item.paySummary.wait.toFixed(2)}} Р</td>
            <td class="text-no-wrap">{{$tools.date(item.begin)}} {{$tools.time(item.begin)}} - {{$tools.time(item.end)}}</td>
          </tr>
        </template>
      </table-view>
      <visit :visitId.sync="visitId" @cancel="$refs.visits.load(true)"/>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import Visit1 from "@/views/Visit/Visit";
import VisitStatus from "@/components/VisitStatus";
import Api from "@/Api";
import TableView from "@/components/TableView";
import LabelView from "@/components/LabelView";
import LayoutView from "@/components/LayoutView";

export default {
  components: {LayoutView, LabelView, TableView, VisitStatus, Visit:Visit1},
  props:['visitsUnpayed'],
  data: () => ({
    visitId:null,
    statusesState: new State(),
    statuses:Api.model.Visit.statuses,
    url:null,
    remain:false,

    filters:{
      id: null,
      statuses: [],
      remain:null,
    },
  }),
  mounted(){
    if(this.visitsUnpayed) {
      this.remain = true;
      this.$emit('update:visitsUnpayed', false);
    }
    this.load();
  },
  watch:{
    remain(){
      this.filters.remain===0 ? this.filters.remain=-1 : this.filters.remain=0;
      this.load();
    }
  },
  methods:{
    load(){
      this.url = '/api/visit/byClient/'+this.$route.params.id+'/?filters='+
          JSON.stringify(this.filters, (key, value) =>{
            if(value===-1)
              value = null;
            if (!this.$tools.isEmpty(value))
              return value;
          });
    }
  },
}
</script>
