<template>
  <layout-view
      navTitle="Фильтры"
  >
    <template v-slot:nav>
      <div class="px-4 pb-2">
        <label-view title="Интервал">
            <template v-slot:content>
                <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-bind="attrs" v-on="on"
                                :value="$tools.date(dateFrom)"
                                label="С" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
                    </template>
                    <v-date-picker :first-day-of-week="1" v-model="dateFrom" color="primary" show-current class="mb-4" no-title/>
                </v-menu>
                <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-bind="attrs" v-on="on"
                                :value="$tools.date(dateTo)"
                                label="По, включительно" class="mt-2 mb-4" outlined dense hide-details="auto" readonly/>
                    </template>
                    <v-date-picker :first-day-of-week="1" v-model="dateTo" color="primary" show-current class="mb-4" no-title/>
                </v-menu>
            </template>
        </label-view>
        <label-view title="Тип платежа">
            <template v-slot:content>
                <v-list class="pa-0" nav>
                    <v-list-item
                            v-for="key in Object.keys(types)" :key="key"
                            @click="typesSelected.filter((v)=>v===key).length===0 ? typesSelected.push(key) : typesSelected = typesSelected.filter((v)=>v!==key);"
                            class="mb-0"
                    >
                        <v-checkbox :value="typesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                        <div>
                            <v-icon size="18" :color="types[key].color" class="mr-2">{{ types[key].icon }}</v-icon>
                            {{ types[key].name }}
                        </div>
                    </v-list-item>
                </v-list>
            </template>
        </label-view>
        <label-view title="Способ расчета">
            <template v-slot:content>
                <v-list class="pa-0" nav>
                    <v-list-item
                            v-for="key in Object.keys(sources)" :key="key"
                            @click="sourcesSelected.filter((v)=>v===key).length===0 ? sourcesSelected.push(key) : sourcesSelected = sourcesSelected.filter((v)=>v!==key);"
                            class="mb-0"
                    >
                        <v-checkbox :value="sourcesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                        <div>
                            <v-icon size="18" color="type.color" class="mr-2">{{ sources[key].icon }}</v-icon>
                            {{ sources[key].name }}
                        </div>
                    </v-list-item>
                </v-list>
            </template>
        </label-view>
        <device-cash-box-select class="mt-4 mb-4" @selected="cashBoxsSelected"/>
        <insur-select @selected="insurSelected"/>
        <v-switch class="pl-2" v-model="notAccepted" inset dense>
            <template v-slot:label>
                <div class="body-2 grey--text text--darken-3">Не подтвержден</div>
            </template>
        </v-switch>
        <v-text-field label="Сумма" v-model="amount" outlined dense hide-details="auto" class="mb-4" clearable/>
        <v-text-field label="ID" v-model="id" class="mb-2" outlined dense hide-details="auto" clearable/>
      </div>
    </template>
    <template v-slot:widgets>
      <v-row>
        <v-col cols="4">
          <card-view title="Личный счет" :loading="paySummaryState.isLoading" minHeight="128">
            <template v-if="paySummary!==null" v-slot:content>
              <h2 class="font-weight-medium">{{ $tools.price(paySummary.accountBalance) }}</h2>
            </template>
            <template v-if="paySummary!==null" v-slot:actions>
              <v-row no-gutters>
                <v-col>
                  <v-btn color="green" text block @click="payCreateState.modalOpen()">
                    <v-icon class="mr-2">mdi-arrow-up-circle</v-icon>Пополнить
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="red" text block @click="payAccountOutCreateState.modalOpen()">
                    <v-icon class="mr-2">mdi-arrow-down-circle</v-icon>Снять
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="blue" text block @click="payAccountTransferCreateState.modalOpen()">
                    <v-icon class="mr-2">mdi-arrow-right-bold-circle</v-icon>Перенести
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </card-view>
        </v-col>
        <v-col cols="2">
          <card-view title="Начислено" :loading="paySummaryState.isLoading" minHeight="128">
            <template v-if="paySummary!==null" v-slot:content>
              <h2 class="font-weight-medium mb-13">{{ $tools.price(paySummary.total) }}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="2">
          <card-view title="Оплачено" :loading="paySummaryState.isLoading" minHeight="128">
            <template v-if="paySummary!==null" v-slot:content>
              <h2 :class="'font-weight-medium'+(paySummary.success===0 ? ' mb-13' : '')">{{ $tools.price(paySummary.success) }}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="2">
          <card-view title="Осталось оплатить" :loading="paySummaryState.isLoading" minHeight="128">
            <template v-if="paySummary!==null" v-slot:content>
              <h2 :class="'font-weight-medium'+(paySummary.wait===0 ? ' mb-13' : '')">
                <v-icon size="21" :color="paySummary.wait===0 ? 'success' : 'warning'" class="position-absolute">
                  {{paySummary.wait===0 ? 'mdi-check-circle' : 'mdi-alert-circle'}}
                </v-icon>
                <span class="ml-7">{{ $tools.price(paySummary.wait) }}</span>
              </h2>
            </template>
            <template v-if="paySummary!==null && paySummary.wait>0" v-slot:actions>
              <v-row no-gutters>
                <v-col>
                  <v-btn @click="$emit('visitsUnpayed')" color="primary" text block>Просмотр</v-btn>
                </v-col>
              </v-row>
            </template>
          </card-view>
        </v-col>
        <v-col cols="2">
          <card-view title="Не подтверждено" :loading="paySummaryState.isLoading" minHeight="128">
            <template v-if="paySummary!==null" v-slot:content>
              <h2 :class="'font-weight-medium'+(paySummary.successNotAccept===0 ? ' mb-13' : '')">
                <v-icon size="21" :color="paySummary.successNotAccept===0 ? 'success' : 'warning'" class="position-absolute">
                  {{paySummary.successNotAccept===0 ? 'mdi-check-circle' : 'mdi-alert-circle'}}
                </v-icon>
                <span class="ml-7">{{ $tools.price(paySummary.successNotAccept) }}</span>
              </h2>
            </template>
            <template v-if="paySummary!==null && paySummary.successNotAccept>0" v-slot:actions>
              <v-row no-gutters>
                <v-col>
                  <v-btn @click="filters={id:null,types:['pay'],sources:[],amount:null,accept:0};load()" color="primary" text block>Просмотр</v-btn>
                </v-col>
              </v-row>
            </template>
          </card-view>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content="{height}">
      <graphql-table-view
          title="Список"
          query='
            query Query(
              $page: Int
              $onPage: Int
              $sortBy: String
              $sortDesc: Boolean
              $types: String
              $created_ge: String
              $created_le: String
              $sources: String
              $notAccepted: Boolean
              $amount: Float
              $id: Int
              $insurs: String
              $kktName: String
              $userId: Int
            ){
              Pay(
                page: $page
                onPage: $onPage
                sortBy: $sortBy
                sortDesc: $sortDesc
                types:$types
                created_ge:$created_ge
                created_le:$created_le
                sources:$sources
                notAccepted:$notAccepted
                amount:$amount
                id:$id
                insurs:$insurs
                kktName:$kktName
                userId:$userId
              ){
                id
                type
                amount
                amountPayed
                amountCash
                amountCard
                amountBankAccount
                amountAccount
                amountOms
                amountDms
                amountContract
                amountInsur
                insur{
                  id
                  name
                }
                accountBalance
                kktName
                terminalName
                tranId
                comment
                created
                isAcceptBankAccount
                isAcceptOms
                isAcceptDms
                isAcceptContract
                operatorUser{
                  id
                  name
                  type
                }
              }
            }
          '
          :queryVars="queryVars"
          queryMainEntity="Pay"
          :headers="[
            { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
            { text: 'Дата', value: 'created', class:'text-no-wrap', sortable: false },
            { text: 'Тип платежа', value: 'type', class:'text-no-wrap', sortable: false },
            { text: 'Способы расчета', value: 'source', class:'text-no-wrap', sortable: false },           
            { text: 'Баланс личного счета', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Банковская карта', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Наличные', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Личный счёт', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Расчётный счет', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Контракт', sortable: false, class:'text-no-wrap text-right'},
            { text: 'ДМС', sortable: false, class:'text-no-wrap text-right'},
            { text: 'ОМС', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Организация', sortable: false, class:'text-no-wrap text-right'},
            { text: 'Сумма', value: 'amount', class:'text-no-wrap', sortable: false },
            { text: 'Комментарий', value: 'comment', class:'text-no-wrap', sortable: false },
            { text: 'Кассир', value: 'operatorUser', class:'text-no-wrap', sortable: false },
            { text: 'Фискальный регистратор', value: 'cashBox', class:'text-no-wrap', sortable: false },
            { text: 'Наименование организации', value: 'insur.name', class:'text-no-wrap', sortable: false },
          ]"
          :height="height"
          ref="table"
          table-class="pays"
        >
          <template v-slot:item="{item}">
            <tr @click="payId=item.id">
              <td class="text-no-wrap">{{item.id}}</td>
              <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
              <td class="text-no-wrap">
                  <v-icon size="18" class="ml-n1 mr-2" :color="types[item.type].color">{{types[item.type].icon}}</v-icon>
                  {{types[item.type].name}}
              </td>
              <td class="text-no-wrap">
                  <pay-sources :pay="item" compact/>
              </td>
              <td class="text-no-wrap">{{item.type==="internalIn" || item.type==="internalOut" ? '' : $tools.price(item.accountBalance)}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountCard, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountCash, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountAccount, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountBankAccount, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountContract, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountDms, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountOms, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amountInsur, '-')}}</td>
              <td class="text-no-wrap">{{$tools.price(item.amount, '-')}}</td>
              <td class="text-no-wrap">
                <tooltip v-if="!$tools.isEmpty(item.comment)" bottom>              
                  {{$tools.truncateText(item.comment,30)}}
                  <template v-slot:content>
                    <vue-markdown>{{item.comment}}</vue-markdown>
                  </template>
                </tooltip>
              </td>
              <td class="text-no-wrap"><user-button-view :user="item.operatorUser" :type="item.operatorUser.type" link/></td>
              <td class="text-no-wrap">
                  <pay-devices :pay="{kktName:item.kktName}"/>
              </td>
              <td v-if="item.insur" class="text-no-wrap">{{item.insur.name}}</td>
              <td v-else>-</td>      
            </tr>
          </template>
      </graphql-table-view>
    </template>
    <template v-slot:footer>
      <pay-create-account-in
          :open="payCreateState.isModalOpen"
          :userId="$router.currentRoute.params.id"
          @complete="payCreateState.modalClose();loadPaySummary();$refs.table.load(true)"
      />
      <pay-create-account-out
          :open="payAccountOutCreateState.isModalOpen"
          :userId="$router.currentRoute.params.id"
          @complete="payAccountOutCreateState.modalClose();loadPaySummary();$refs.table.load(true)"
      />
      <pay-create-account-transfer
          :open="payAccountTransferCreateState.isModalOpen"
          :userId="$router.currentRoute.params.id"
          @complete="payAccountTransferCreateState.modalClose();loadPaySummary();$refs.table.load(true)"
      />
      <pay :id="payId" @cancel="payId=null;loadPaySummary();$refs.table.load(true)"/>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import CardView from "@/components/CardView";
import Pay from "@/views/Pay/Pay";
import moment from "moment";
import LayoutView from "@/components/LayoutView";
import PayCreateAccountIn from "@/views/Pay/PayCreateAccountIn.vue";
import PaySources from "@/views/Pay/components/PaySources.vue";
import PayDevices from "@/views/Pay/components/PayDevices.vue";
import UserButtonView from "@/components/UserButtonView.vue";
import GraphqlTableView from "@/components/GraphqlTableView.vue";
import LabelView from "@/components/LabelView.vue";
import InsurSelect from "@/views/Insur/components/InsurSelect.vue";
import DeviceCashBoxSelect from "@/views/Device/component/DeviceCashBoxSelect.vue";
import PayCreateAccountOut from "@/views/Pay/PayCreateAccountOut.vue";
import PayCreateAccountTransfer from "@/views/Pay/PayCreateAccountTransfer.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  components: {
    PayCreateAccountTransfer,
    PayCreateAccountOut,
    DeviceCashBoxSelect, InsurSelect, LabelView,
    GraphqlTableView, UserButtonView, PayDevices,
    PaySources,
    PayCreateAccountIn,
    LayoutView,
    CardView, 
    Pay,
    Tooltip, 
  },
  data: () => ({
    paySummaryState: new State(),
    paySummary:null,

    // dateFrom:moment().subtract(1, "month").format('YYYY-MM-DD'),
    // dateTo:moment().format('YYYY-MM-DD'),
    dateFrom:null,
    dateTo:null,
    types:Api.model.Pay.types,
    typesSelected:[],
    sources:Api.model.Pay.sources,
    sourcesSelected:[],
    notAccepted:null,
    amount:null,
    id:null,
    payId:null,

    queryVars: {
      // created_ge:moment().subtract(1, "month").format('YYYY-MM-DD 00:00:00'),
      // created_le:moment().format('YYYY-MM-DD 23:59:59'),
      created_ge:null,
      created_le:null,
      types:'',
      sources:'',
      insurs:null,
      userId:null
    },

    payCreateState: new State(),
    payAccountOutCreateState: new State(),
    payAccountTransferCreateState: new State(),
  }),
  mounted() {
    this.queryVars.userId = parseInt(this.$router.currentRoute.params.id);
    this.loadPaySummary();
  },
  watch:{
    dateFrom(){
      this.queryVars.created_ge = moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00');
      this.$refs.table.load();
      this.loadPaySummary();
    },
    dateTo(){
      this.queryVars.created_le = moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59');
      this.$refs.table.load();
      this.loadPaySummary();
    },
    typesSelected(){
      this.queryVars.types = '';
      this.typesSelected.forEach(v=>{
        this.queryVars.types+=v+',';
      });
      this.$refs.table.load();
      this.loadPaySummary();
    },
    sourcesSelected(){
      this.queryVars.sources = '';
      this.sourcesSelected.forEach(v=>{
        this.queryVars.sources+=v+',';
      });
      this.$refs.table.load();
      this.loadPaySummary();
    },
    notAccepted(){
      if(this.notAccepted){
        this.queryVars.notAccepted = true;
      } else {
        delete this.queryVars.notAccepted;
      }
      this.$refs.table.load();
      this.loadPaySummary();
    },
    amount(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.amount)){
          this.queryVars.amount = parseFloat(this.amount);
        } else {
          delete this.queryVars.amount;
        }
        this.$refs.table.load();
        this.loadPaySummary();
      });
    },
    id(){
      this.$tools.throttle(()=>{
        if(!this.$tools.isEmpty(this.id)){
          this.queryVars.id = parseInt(this.id);
        } else {
          delete this.queryVars.id;
        }
        this.$refs.table.load();
        this.loadPaySummary();
      });
    }
  },
  methods:{
    loadPaySummary(){
      this.paySummaryState.stateLoading();
      Api.service.pay.paySummary(
          this.$router.currentRoute.params.id,
          this.dateFrom,
          this.dateTo,
          (v) => {
            this.paySummary = v;
            this.paySummaryState.stateSuccess();
          }, () => {
            this.paySummaryState.stateError();
          });
    },
    cashBoxsSelected(v){
      if(this.$tools.isEmpty(v))
        delete this.queryVars.kktName;
      else
        this.queryVars.kktName = v;
      this.$refs.table.load();
    },
    insurSelected(v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.id+',';
      });
      this.queryVars.insurs = r;
      this.$refs.table.load();
    },
  }
}
</script>