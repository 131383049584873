<template>
  <tr>
    <med-record-update-table-col v-for="(col, index) in cols" :key="'row_'+index" :body="col" :index="index" @updated="colUpdated" :medRecordTemplateFieldValues="medRecordTemplateFieldValues"/>
  </tr>
</template>

<script>
import MedRecordUpdateTableCol from "@/views/MedRecord/MedRecordUpdateTableCol";
export default {
  components: {MedRecordUpdateTableCol},
  props:['body', 'medRecordTemplateFieldValues', 'index'],
  data: () => ({
    cols:[],
  }),
  watch: {
    body: {
      immediate: true,
      handler () {
        this.load();
      }
    }
  },
  methods:{
    load(){
      this.cols = [];
      let bodyData = this.body.replace('<row>', '').replace('</row>', '');
      bodyData.replaceAll(/<col[\s\S]*?<\/col>/g, (match)=>{
        this.cols.push(match);
        return null;
      });
    },
    colUpdated(d){
      this.cols[d.index] = d.body;
      let bodyRow = '';
      this.cols.forEach((v)=>{
        bodyRow +=v;
      });
      this.$emit("updated", {
        index:this.index,
        body:'<row>'+bodyRow+'</row>'
      });
    }
  }
}
</script>