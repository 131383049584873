<template>
  <layout-view>
    <template v-slot:content="{height}">
      <n-table-view
          name="Список"
          :genRequestBuilder="genRequestBuilder"
          :height="height"
          :headers="[
            { text: 'ID', value: 'id', class:'text-no-wrap'},
            { text: 'Название', value: 'name', class:'text-no-wrap'},
            { text: 'Текст', value: 'text', class:'text-no-wrap'},
            { text: 'Создано', value: 'created',  class:'text-no-wrap'},
            { text: 'Отправлено', value: 'sended',  class:'text-no-wrap'},
            { text: 'Прочтено', value: 'readed',  class:'text-no-wrap'},
          ]"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">{{item.name}}</td>
            <td class="text-wrap">{{item.text}}</td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">{{$tools.dateTime(item.sended)}}</td>
            <td class="text-no-wrap">{{$tools.dateTime(item.readed)}}</td>
          </tr>
        </template>
      </n-table-view>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView.vue";
import {Entity_Notif, Entity_User} from "../../../EntityStoreCacheService";
import NTableView from "@/componentsV2/base/NTableView.vue";

export default {
  components: {
    NTableView,
    LayoutView,
  },
  methods: {
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_Notif()
          .selects(Entity_Notif.id)
          .selects(Entity_Notif.name)
          .selects(Entity_Notif.text)
          .selects(Entity_Notif.created)
          .selects(Entity_Notif.sended)
          .selects(Entity_Notif.readed)
          .filterAnd([Entity_Notif.entity, Entity_Notif.user,Entity_User.id].join('.')+'=?', this.$router.currentRoute.params.id)
          .page(page)
          .onPage(onPage);
      sortFields.forEach(v=>{
        rb.order(v.field, v.isAsc)
      })
      /*
        если нужны данные которые в таблице, можно добавить прослушиватель который будет возвращать текущее состояние
        rb.addStateCallback((v)=>{
          console.log(v);
        })
       */
      return rb;
    }
  }
};
</script>