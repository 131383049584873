var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-view',{scopedSlots:_vm._u([{key:"content",fn:function({height}){return [_c('n-table-view',{attrs:{"name":"Список","genRequestBuilder":_vm.genRequestBuilder,"height":height,"headers":[
          { text: 'ID', value: 'id', class:'text-no-wrap'},
          { text: 'Название', value: 'name', class:'text-no-wrap'},
          { text: 'Текст', value: 'text', class:'text-no-wrap'},
          { text: 'Создано', value: 'created',  class:'text-no-wrap'},
          { text: 'Отправлено', value: 'sended',  class:'text-no-wrap'},
          { text: 'Прочтено', value: 'readed',  class:'text-no-wrap'},
        ]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-wrap"},[_vm._v(_vm._s(item.text))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$tools.dateTime(item.created)))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$tools.dateTime(item.sended)))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$tools.dateTime(item.readed)))])])]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }