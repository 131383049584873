<template>
  <v-sheet outlined rounded>
    <v-toolbar flat color="grey lighten-4" height="48" rounded>
      <v-icon color="orange" size="22">mdi-lightbulb-variant</v-icon>
      <div class="body-2 ml-2">{{name}}</div>
    </v-toolbar>
    <div>
      <slot name="default"/>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props:['name'],
}
</script>