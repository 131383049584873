<template>
  <div>
    <dialog-view
        :open="open"
        title="Снять с личного счета"
        @cancel="complete()"
        content-full-size
        width="540"
    >
      <template v-slot:content>
        <div v-if="payState.isInit">
          <div class="mx-4 my-2">
            <v-row>
              <v-col>
                <div class="mb-1 body-2">Сумма</div>
                <div class="text-h5">{{$tools.price(amountPayed)}}</div>
              </v-col>
            </v-row>
          </div>
          <v-divider/>
          <div class="mx-4 mt-2 mb-4">
            <div class="mb-3 body-2">Передано</div>
            <v-form ref="form">
              <alert-view v-if="!$tools.isEmpty(devicesError)" :text="devicesError+'\n Расчет наличными и банковской картой недоступны'" icon="mdi-information-outline"/>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="amountCash"
                      :disabled="!$tools.isEmpty(amountInsur)"
                      label="Наличными"
                      outlined dense hide-details="auto" class="big" clearable>
                    <template v-slot:label>
                      <v-icon small class="mr-2">mdi-cash</v-icon>Наличными
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12 mx-n4">
                  <v-divider style="width:calc(100% + 32px);max-width:calc(100% + 32px)"/>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="mb-2 body-2">Передано организации</div>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                      v-model="amountInsur"
                      :disabled="!$tools.isEmpty(amountCash)"
                      outlined dense hide-details="auto" class="big" clearable
                  >
                    <template v-slot:label>
                      Сумма
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="8" class="pt-0">
                  <insur-select single @selected="(v)=>insurId=v"/>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider/>
          <div class="mx-4 my-4">
            <v-textarea v-model="comment" label="Комментарий к платежу" outlined dense hide-details auto-grow rows="2" clearable/>
          </div>
          <v-divider/>
          <div class="overflow-hidden">
            <div class="mx-n2">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>Кассовое оборудование</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-2" no-gutters>
                      <v-col class="pr-2">
                        <v-select
                            label="Фискальный регистратор"
                            v-model="cashBox"
                            :items="cashBoxes"
                            outlined dense hide-details item-text="name" item-value="id">
                          <template v-slot:selection="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                          <template v-slot:item="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="pl-2">
                        <v-select
                            label="Эквайринговый терминал"
                            v-model="terminal"
                            :items="terminals"
                            outlined dense hide-details item-text="name" item-value="id">
                          <template v-slot:selection="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                          <template v-slot:item="{item}">
                            <div class="my-2">
                              <div class="body-2 font-weight-medium">{{item.name}}</div>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <v-divider/>
          <div class="mx-4 my-4">
            <v-btn @click="pay()" block depressed color="primary" :disabled="amountPayed===0">Снять</v-btn>
          </div>
        </div>
        <div v-if="payState.isLoading">
          <div v-if="payState.step===0" class="pb-4">
            <progress-page icon="mdi-account"/>
            <div class="text-center mt-n4">Сбор информации о кассире</div>
          </div>
          <div v-if="payState.step===1" class="pb-4">
            <progress-page icon="mdi-credit-card-outline"/>
            <div class="text-center mt-n4">Производиться оплата банковской картой<br/>через "{{terminal.name}}"</div>
          </div>
          <div v-if="payState.step===2" class="pb-4">
            <progress-page icon="mdi-cash-register"/>
            <div class="text-center mt-n4">Производиться регистрация платежа<br/>через "{{cashBox.name}}"</div>
          </div>
          <div v-if="payState.step===3" class="pb-4">
            <progress-page icon="mdi-content-save"/>
            <div class="text-center mt-n4">Сохранение платежа</div>
          </div>
        </div>
        <div v-if="payState.isSuccess" class="pb-4">
          <v-sheet class="position-relative d-flex align-center ma-auto" :width="96" :height="96" color="transparent">
            <v-icon class="position-absolute d-block mx-auto" style="margin-left:calc(50% - 27px)!important" color="success" size="54">mdi-check-circle</v-icon>
          </v-sheet>
          <div class="text-center mt-n4">Платеж успешно выполнен</div>
          <div class="mx-4 mt-4">
            <v-btn @click="complete()" block depressed color="primary">OK</v-btn>
          </div>
        </div>
      </template>
    </dialog-view>
  </div>

</template>

<script>
import DialogView from "@/components/DialogView";
import Kkm from "@/Kkm";
import State from "@/plugins/state";
import ProgressPage from "@/components/ProgressPage.vue";
import axios from "axios";
import Api from "@/Api";
import AlertView from "@/components/AlertView.vue";
import InsurSelect from "@/views/Insur/components/InsurSelect.vue";
export default {
  components: {InsurSelect, AlertView, ProgressPage, DialogView},
  props:['open', 'userId', 'visitServices'],
  data: () => ({
    amountCash:null,
    amountInsur:null,
    insurId:null,
    comment:null,

    kkm: new Kkm(),
    devicesError:null,
    cashBoxes:[],
    cashBox:null,
    terminals:[],
    terminal:null,

    payState: new State(),
  }),
  watch:{
    open(){
      if(this.open) {
        this.amountCash = null;
        this.amountInsur = null;
        this.comment = null;

        this.devicesError = null;
        this.cashBoxes = [];
        this.cashBox = null;
        this.terminals = [];
        this.terminal = null;
        this.kkm.devices((res)=>{
          this.cashBoxes = res.filter(v=>v.typeName==='Фискальный регистратор');
          this.cashBox = this.cashBoxes[0];

          this.terminals = res.filter(v=>v.typeName==='Эквайринговый терминал');
          this.terminal = this.terminals[0];

          this.cashBoxes.forEach(v1=>{
            this.kkm.deviceStatusSessionError(v1, (res)=>{
              if(res!==null)
                this.devicesError = res+' "'+v1.name+'"';
            }, e=>{
              this.devicesError = e+' "'+v1.name+'"';
            });
          });
        }, e=>{
          this.devicesError = e;
        });

        this.payState.stateInit();
      }
    },
    'amountCash'(str) {
      this.amountCash = this.$tools.priceFormatter(str);
    },
    'amountInsur'(str) {
      this.amountInsur = this.$tools.priceFormatter(str);
    },
  },
  computed:{
    amountPayed(){
      let v = 0;
      v += this.$tools.parseNumber(this.amountCash);
      v += this.insurId==null ? 0 : this.$tools.parseNumber(this.amountInsur);
      return v;
    }
  },
  methods: {
    loadUser(success){
      this.payState.step = 0;
      axios.get(Api.host+'/user/status/')
          .then((res)=> {
            axios.get(Api.host + '/user/' + res.data.id + '/')
                .then((res) => {
                  success(res);
                }).catch(() => this.payState.stateInit());
          }).catch(()=>this.payState.stateInit());
    },
    payCreate(cashBox=null, terminal=null){
      this.payState.step = 3;

      let data = {
        type:'accountOut',
        userId:this.userId,
        comment:this.comment,
      };
      if(this.$tools.parseNumber(this.amountCash)>0) data.amountCash = this.$tools.parseNumber(this.amountCash);
      if(this.$tools.parseNumber(this.amountInsur)>0 && this.insurId!=null){
        data.amountInsur = this.$tools.parseNumber(this.amountInsur);
        data.insurId = this.insurId;
      }

      if(cashBox!==null)
        data.kktName=cashBox.name;
      if(terminal!==null)
        data.terminalName=terminal.name;

      axios.post(Api.host+'/pay/pay/', data)
          .then(()=> {
            this.payState.stateSuccess();
          }).catch((e)=>{
        this.payState.stateInit();
        this.$store.commit('alertError', 'Не удалось сохранить платеж: '+e.response.data.message);
      });
    },
    pay(){
      if (this.$refs.form.validate()) {

        if(this.$tools.parseNumber(this.amountInsur)>0 && this.insurId!=null){
          this.payState.stateLoading();
          this.payCreate();
          return null;
        }

        let arr = [{
          name: 'Аванс',
          count: 1,
          price: this.amountPayed,
          priceTotal: this.amountPayed,
          tax: -1,
          type: 'service'//'prepaid'
        }];

        this.payState.stateLoading(() => {
          this.loadUser((res) => {
            this.payState.step = 2;
            this.kkm.payRegisterCancel(
                this.cashBox,
                res.data.lastName + ' ' + res.data.firstName,
                res.data.userProfile.inn,
                this.$tools.parseNumber(this.amountCash),
                0,
                arr,
                () => {
                  this.payCreate(this.cashBox)
                }, e => {
                  this.payState.stateInit();
                  this.$store.commit('alertError', 'Фискальный регистратор: ' + e);
                });
          });
        });
      }
    },
    complete(){
      this.$emit('complete');
    }
  }
}
</script>
