<template>
  <v-dialog @click:outside="closeDialog" v-model="dialog" max-width="400" :persistent="loadState.isLoading">
    <v-card
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="'transition-linear-out-slow-in '+(dragover ? 'grey lighten-2' : '')"
    >
      <v-card-text class="pb-0">
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <div :class="'position-absolute transition-linear-out-slow-in '+(dragover ? 'mt-n4' : 'mt-n0')">
            <v-icon v-if="loadState.isInit" size="60">mdi-cloud-upload</v-icon>
            <v-icon v-if="loadState.isLoading" size="60" color="primary">mdi-cloud-refresh</v-icon>
            <v-icon v-if="loadState.isSuccess" size="60" color="success">mdi-cloud-check</v-icon>
            <v-icon v-if="loadState.isError" size="60" color="red">mdi-cloud-alert</v-icon>
          </div>
          <div class="pt-10 mt-16 text-center mb-2">
            <input ref="explorer" type="file" class="d-none" :multiple="multiple">
            <div v-if="loadState.isInit">
              <span>Перетащите</span>
              <span> или </span>
              <a href="#" class="blue--text" @click.prevent="$refs.explorer.click()">выберите</a>
              <span> {{multiple ? 'файлы' : 'файл'}}</span>
            </div>
            <div v-if="loadState.isLoading">
              <span>{{multiple ? 'Файлы загружаются...' : 'Файл загружается...'}}</span>
            </div>
            <div v-if="loadState.isSuccess">
              <span>{{multiple ? 'Файлы успешно загружены' : 'Файл успешно загружен'}}</span>
            </div>
            <div v-if="loadState.isError">
              <span>При загрузке произошла ошибка</span>
            </div>
          </div>
        </v-row>
        <div v-if="uploadedFiles.length > 0" class="overflow-y-auto ml-n5 mr-n5" style="max-height: 300px">
          <v-list color="transparent px-5">
            <v-list-item v-for="(item, index) in uploadedFiles" :key="'uploadedFiles'+index" class="px-0">
              <v-row no-gutters>
                <v-col>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ (item.size/1024/1024).toFixed(2) }} МБ</v-list-item-subtitle>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                      @click.stop="loadState.isInit ? removeFile(item.name) : null"
                      icon
                  >
                    <v-progress-circular
                        :value="uploadedFilesProgress[index]"
                        size="24"
                        :width="2"
                        :color="color(uploadedFilesProgress[index])"
                    >
                      <v-icon v-if="color(uploadedFilesProgress[index])==='grey' || color(uploadedFilesProgress[index])==='red'" size="16">mdi-close-thick</v-icon>
                      <v-icon v-if="color(uploadedFilesProgress[index])==='primary'" size="16">mdi-upload</v-icon>
                      <v-icon v-if="color(uploadedFilesProgress[index])==='green'" size="16">mdi-check-bold</v-icon>
                    </v-progress-circular>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="closeDialog" :disabled="!loadState.isInit">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            v-if="loadState.isInit"
            color="primary"
            text @click="submit"
            :disabled="this.uploadedFiles.length===0"
            :loading="loadState.isLoading"
        >
          Загрузить
        </v-btn>
        <v-btn v-if="loadState.isSuccess || loadState.isError" text @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";

export default {
  props: ['dialog', 'multiple', 'url'],
  data: () => ({
    loadState: new State(),
    dragover: false,
    uploadedFiles: [],
    uploadedFilesProgress: [],
    uploadedFilesResult: [],
  }),
  mounted() {
    this.loadState.stateInit();
  },
  updated() {
    if (this.$refs.explorer!=null){
      this.$refs.explorer.onchange = (event)=>{
        Array.from(event.target.files).forEach((v)=>{
          this.uploadedFiles.push(v);
        });
        this.$refs.explorer.value = null;
      };
    }
  },
  methods: {
    color(v){
      if(typeof v==='undefined') return 'grey';
      if(v===-1) return 'red';
      if(v===100) return 'green';
      if(v>=0) return 'primary';
    },
    closeDialog() {
      if (!this.loadState.isLoading){
        this.loadState.stateInit();
        this.$emit("update:dialog", false);
        this.$emit("uploadComplete", this.uploadedFilesResult);
        this.uploadedFiles = [];
        this.uploadedFilesProgress = [];
        this.uploadedFilesResult = [];
      }
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
          file => file.name === fileName
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    onDrop(e) {
      this.dragover = false;
      if (!this.loadState.isSuccess) {
        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          alert('Загрузить возможно только один файл');
        }
        // Add each file to the array of uploaded files
        else
          Object.values(e.dataTransfer.files).forEach(element =>
              this.uploadedFiles.push(element)
          );
      }
    },
    submit() {
      if (this.uploadedFiles.length > 0) {
        this.loadState.stateLoading();
        this.uploadedFilesProgress = [];
        // eslint-disable-next-line no-unused-vars
        this.uploadedFiles.forEach((file, index)=>{
          this.uploadedFilesProgress[index] = 0;
          let formData = new FormData();
          formData.append("file", file);
          axios.post(this.url, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
            withCredentials: true,
            onUploadProgress: progressEvent =>{
              this.uploadedFilesProgress[index] = (progressEvent.loaded / progressEvent.total) * 100;
              this.uploadedFilesProgress = this.uploadedFilesProgress.slice();
            }
          })
          .then((v)=>{
            if (typeof v.data.path!=='undefined')
              this.uploadedFilesResult[index] = v.data.path;
            let total = 0;
            this.uploadedFilesProgress.forEach((v)=>{
              total+=v;
            });
            if (total===this.uploadedFilesProgress.length*100){
              this.loadState.stateSuccess();
            }
          })
          .catch(e=>{
            console.log(e);
            this.uploadedFilesProgress[index] = -1;
            this.uploadedFilesProgress = this.uploadedFilesProgress.slice();
            this.loadState.stateError();
          });
        })
      }
    }
  }
}
</script>