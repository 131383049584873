<template>
  <v-sheet class="d-inline-block">
    <span class="clickable blue--text text--darken-2 pr-1" @click="isOpen=true">
      {{editorField.value}}
    </span>
    <div
        v-for="(file, index) in files" :key="'file'+index"
        class="d-inline-block clickable blue--text text--darken-2 pr-2"
        @click="filesStates[index].modalOpen(file)">
      <v-icon dense class="mr-1" color="blue">mdi-file-document-outline</v-icon> {{file.name}}
    </div>
    <v-dialog
        v-for="(fileState, index) in filesStates" :key="'preview'+index"
        v-model="fileState.isModalOpen"
        max-width="800"
        :loading="fileState.isLoading">
      <v-card v-if="fileState.modalData!=null">
        <v-toolbar dense elevation="0" color="transparent">
          <v-toolbar-title>{{fileState.modalData.name}}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="download(fileState.modalData.path);fileState.modalClose()" class="mr-2">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="fileState.modalClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <img
            v-if="fileState.modalData.path.includes('.jpeg') || fileState.modalData.path.includes('.jpg') || fileState.modalData.path.includes('.png')"
            class="full-width float-left"
            :src="fileState.modalData.path"
        >
        <div v-else>
          <div class="body-2 px-2 py-8 text-center">Предосмотр недоступен</div>
        </div>
      </v-card>
    </v-dialog>
    <uploader
        :dialog.sync="isOpen"
        :multiple="true"
        :url="'/api/medRecord/medRecordValue/file/upload/'"
        @uploadComplete="uploadComplete"
    />
  </v-sheet>
</template>

<script>
import Uploader from "@/components/Uploader";
import State from "@/plugins/state";

export default {
  components: {Uploader},
  props:['editorField', 'editorFieldOpen'],
  data: () => ({
    isOpen: false,
    files:[],
    filesStates: []
  }),
  mounted(){
    this.load();
  },
  methods:{
    load(){
      this.files = [];
      this.filesStates = [];
      this.editorField.currentValue.split(',').forEach((v)=>{
        let name = v;
        if (v!==this.editorField.value){
          name = name.substring(name.lastIndexOf('/')+1, name.length);
          name = name.substring(0, name.indexOf('.'));
          this.files.push({
            path:v,
            name:name
          });
          this.filesStates.push(new State());
        }
      });
    },
    uploadComplete(paths){
      if (paths.length>0){
        this.editorField.customValue = '';
        paths.forEach((v)=>{
          this.editorField.customValue+=v+',';
        });
        this.editorField.customValue = this.editorField.customValue.substring(0, this.editorField.customValue.length-1);
        this.$parent.fieldUpdateSelectCustom(this.editorField);
        this.load();
      }
    },
    download(path){
      window.open(path);
    }
  }
}
</script>