<template>
  <div>
   <text-field name="Рост (см)" :value.sync="height"/>
   <text-field name="Вес (кг)" :value.sync="weight"/>
  </div> 
</template>
<script>
import TextField from "@/componentsV2/base/TextField.vue";
export default {
  components:{TextField},
  //props: ['medRecordValue','editorField','birth','gender'],
  data: () => ({
   height:0,
   weight:0,
   imt:0
  }),
  watch:{
   height(){
    this.imtCalc();
   },
   weight(){
    this.imtCalc();
   }
  },
  methods: {
    imtCalc() {
      this.imt = `Рост: ${this.height} см, вес: ${this.weight} кг, ИМТ: ${(this.weight/((this.height/100) ** 2)).toFixed(1)}`;

      if(this.weight>0&&this.height>0){
        this.$emit('calc',this.imt);
      }
    }
  }
}
</script>