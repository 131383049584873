<template>
  <div class="pa-6">
    <v-row>
      <v-col cols="8">
        <UserProfileCard/>
      </v-col>
      <!-- <v-col cols="12" xl="6">
        <ClientEvents/>
      </v-col> -->
    </v-row>
  </div>

</template>

<script>
//import ClientEvents from "@/views/User/Client/ClientEvents";
import UserProfileCard from "@/views/User/UserProfileCard";
export default {
  //components: {UserProfileCard, ClientEvents},
  components: {UserProfileCard},
}
</script>


