<template>
  <autocomplete-single name="Категория пользователя" :items="arr" :select.sync="localSelect" :loading="api.loading"/>
</template>

<script>

import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import GraphqlApi from "@/plugins/graphqlApi";

export default {
  components: {AutocompleteSingle},
  props:[
    'select',
  ],
  data:()=>({
    api: new GraphqlApi(),
    arr: [],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
  mounted() {
    this.localSelect = this.select;
    this.api.requestSingleModelMultipleDataV1(this.arr, 'ComUserCat', '/api/graphql/', `
      query Query{
        ComUserCat(sortBy:"name",sortDesc:false){
          id
          name
        }
      }
    `, null, null, null, true);
  },
}
</script>