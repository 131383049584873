<template>
  <dialog-view
      :title="salaryTemplatesSaveState.step===0 ? 'Сохранить профиль' : salaryTemplatesSaveState.step===1 ? 'Сохранить профиль как новый' : 'Перезаписать профиль'"
      :open="open"
      @cancel="close()"
      content-full-size
  >
    <template v-slot:content>
      <div v-if="salaryTemplatesSaveState.step===0" class="text-center pa-2">
        <v-row no-gutters>
          <v-col class="pr-1">
            <v-btn depressed block @click="salaryTemplatesSaveState.step=1">Как новый</v-btn>
          </v-col>
          <v-col class="pl-1">
            <v-btn depressed block @click="salaryTemplatesSaveState.step=2">Перезаписать</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="salaryTemplatesSaveState.step===1">
        <v-text-field label="Название" v-model="name" class="ma-4" outlined dense hide-details="auto"/>
        <v-textarea label="Комментарий" v-model="comment" class="mx-4 mb-4" rows="2" hide-details auto-grow outlined dense/>
      </div>
      <div v-if="salaryTemplatesSaveState.step===2">
        <v-text-field label="Название" v-model="name" class="ma-4" outlined dense hide-details="auto"/>
        <v-textarea label="Комментарий" v-model="comment" class="mx-4 mb-4" rows="2" hide-details auto-grow outlined dense/>
        <v-select
            label="Перезаписать профиль"
            v-model="salaryTemplate"
            :items="salaryTemplates"
            item-text="name"
            return-object
            class="mx-4 mb-4" outlined dense hide-details="auto"
        >
          <template v-slot:no-data>
            <div class="caption text-center">Не найдено</div>
          </template>
          <template v-slot:item="{item}">
            <div class="my-2">
              <div class="body-2 font-weight-medium">{{item.name}}</div>
              <div v-if="!$tools.isEmpty(item.comment)" class="mt-1">
                <v-icon small>mdi-comment-text-outline</v-icon>
                <div class="caption mt-n5 ml-5">{{item.comment}}</div>
              </div>
            </div>
          </template>
          <template v-slot:selection="{item}">
            <div class="my-2">
              <div class="body-2 font-weight-medium">{{item.name}}</div>
              <div v-if="!$tools.isEmpty(item.comment)" class="mt-1">
                <v-icon small>mdi-comment-text-outline</v-icon>
                <div class="caption mt-n5 ml-5">{{item.comment}}</div>
              </div>
            </div>
          </template>
        </v-select>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn
          v-if="salaryTemplatesSaveState.step!==0"
          @click="salaryTemplatesSave()"
          :loading="salaryTemplatesSaveState.isLoading"
          :disabled="$tools.isEmpty(name)"
          text color="primary">Сохранить</v-btn>
    </template>
  </dialog-view>
</template>

<script>
import DialogView from "@/components/DialogView.vue";
import axios from "axios";
import Api from "@/Api";
import State from "@/plugins/state";

export default {
  components: { DialogView},
  props:['open','salary'],
  data: () => ({
    name:null,
    comment:null,
    salaryTemplates:[],
    salaryTemplatesSaveState: new State(),
    salaryTemplate:null,
  }),
  watch:{
    open(){
      if(this.open){
        this.salaryTemplates = [];
        this.salaryTemplate = null;
        this.name = null;
        this.comment = null;
        Api.graphql(`
          query Query {
              SalaryTemplate {
                id
                name
                comment
                renMode
                ren
                refMode
                ref
                regMode
                reg
                rateMode
                rate
                isRateServiceDiscount
              }
            }
        `, {}, (data)=>{
          if (data.SalaryTemplate.length>0){
            this.salaryTemplates = data.SalaryTemplate;
            this.salaryTemplate = data.SalaryTemplate[0];
            this.name = data.SalaryTemplate[0].name;
            this.comment = data.SalaryTemplate[0].comment;
          }
        });
      }
    },
    salaryTemplate(){
      this.name = this.salaryTemplate.name;
      this.comment = this.salaryTemplate.comment;
    }
  },
  methods: {
    close(){
      setTimeout(()=>{
        this.name = null;
        this.comment = null;
        this.salaryTemplatesSaveState.step=0;
      }, 500);
      this.$emit('update:open', false)
    },
    salaryTemplatesSave(){
      this.salaryTemplatesSaveState.stateLoading();
      let data = this.salary;
      data.name = this.name;
      data.comment = this.comment;

      if(this.salaryTemplatesSaveState.step===0){
        axios.put(Api.host+'/salary/template/'+data.id+'/', data).then(()=>{
          this.salaryTemplatesSaveState.stateSuccess();
          this.close();
          this.$store.commit('alertSuccess', 'Профиль сохранен');
        }).catch(()=>{
          this.salaryTemplatesSaveState.stateError();
        });
      } else if(this.salaryTemplatesSaveState.step===1){
        axios.post(Api.host+'/salary/template/', data).then(()=>{
          this.salaryTemplatesSaveState.stateSuccess();
          this.close();
          this.$store.commit('alertSuccess', 'Профиль создан');
        }).catch(()=>{
          this.salaryTemplatesSaveState.stateError();
        });
      } else if(this.salaryTemplatesSaveState.step===2){
        axios.put(Api.host+'/salary/template/'+this.salaryTemplate.id+'/', data).then(()=>{
          this.salaryTemplatesSaveState.stateSuccess();
          this.close();
          this.$store.commit('alertSuccess', 'Профиль перезаписан');
        }).catch(()=>{
          this.salaryTemplatesSaveState.stateError();
        });
      }
    }
  }  
};
</script>