<template>
  <dialog-view
      :title="name"
      :open="open"
      @cancel="$emit('update:open', false)"
      content-full-size
      :loading="loading"
  >
    <template v-slot:content>
      <v-list dense class="py-0">
        <v-list-item
            v-for="(v, i) in salaryTemplates"
            :key="i"
            @click="$emit('update:open', false);$emit('selected', v);$store.commit('alertSuccess', 'Профиль загружен');"
        >
          <v-list-item-content>
            <v-list-item-title>{{v.name}}</v-list-item-title>
            <div v-if="!$tools.isEmpty(v.comment)" class="mt-1">
              <v-icon small>mdi-comment-text-outline</v-icon>
              <div class="caption mt-n5 ml-5">{{v.comment}}</div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </dialog-view>
</template>

<script>
import DialogView from "@/components/DialogView.vue";
import {Entity_SalaryTemplate, Entity_SalaryTemplateService, Entity_Service} from "../../../EntityStoreCacheService";

export default {
  components: {DialogView},
  props:['name','open'],
  data: () => ({
    salaryTemplates:[],
    loading:false
  }),
  watch:{
    open() {
      if(this.open){
        new Entity_SalaryTemplate()
            .selects(Entity_SalaryTemplate.id)
            .selects(Entity_SalaryTemplate.name)
            .selects(Entity_SalaryTemplate.comment)
            .selects(Entity_SalaryTemplate.createVisitMode)
            .selects(Entity_SalaryTemplate.createVisit)
            .selects(Entity_SalaryTemplate.renMode)
            .selects(Entity_SalaryTemplate.ren)
            .selects(Entity_SalaryTemplate.refMode)
            .selects(Entity_SalaryTemplate.ref)
            .selects(Entity_SalaryTemplate.regMode)
            .selects(Entity_SalaryTemplate.reg)
            .selects(Entity_SalaryTemplate.rateMode)
            .selects(Entity_SalaryTemplate.rate)
            .selects(Entity_SalaryTemplate.rateMaxMode)
            .selects(Entity_SalaryTemplate.rateMax)
            .selects(Entity_SalaryTemplate.isRateServiceDiscount)
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.id].join('.'))
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.ren].join('.'))
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.renMode].join('.'))
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.service,Entity_Service.id].join('.'))
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.service,Entity_Service.name].join('.'))
            .selects([Entity_SalaryTemplate.salaryTemplateServices,Entity_SalaryTemplateService.service,Entity_Service.priceTotal].join('.'))
            .page(1)
            .onPage(1000)
            .order(Entity_SalaryTemplate.name, true)
            .addStateCallback((v)=>{
              this.loading = v.loading;
              if(v.error)
                this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
              if(v.success)
                this.salaryTemplates = v.items;
            })
            .request()
      }
    }
  },
};
</script>