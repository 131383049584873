<template>
  <table v-if="parents.length>0"  cellspacing="0" cellpadding="0" class="full-width">
    <tr>
      <td style="vertical-align: bottom">
        <div class="text-right">
          <client-family-parent-tree :parents="parents[0].parents" :index="index+1"/>
          <div class="mt-4">
            <a :href="'../../'+parents[0].id+'/family/'">
              {{parents[0].lastName}} {{parents[0].firstName}} {{parents[0].secondName}}
            </a>
            <span v-if="index<=2" class="ml-2">
              {{index===0 ? parents[0].userProfile.gender==='male' ? '(Отец)' : '(Мать)' : ''}}
              {{index===1 ? parents[0].userProfile.gender==='male' ? '(Дедушка)' : '(Бабушка)' : ''}}
              {{index===2 ? parents[0].userProfile.gender==='male' ? '(Продедушка)' : '(Пробабушка)' : ''}}
            </span>
            <span v-if="typeof parents[1]==='undefined'">
              (Нет супруга)
            </span>
          </div>
        </div>
      </td>
      <td
          class="px-2 pt-4 text-center"
          style="vertical-align: bottom;width: 0px;">
        <div v-if="typeof parents[1]!=='undefined'">
          <v-chip v-if="parents[1].mariedUser!==null && parents[0].mariedUser.id===parents[1].id" small color="green" dark>Супруги</v-chip>
          <v-chip v-else small color="grey" dark>Не супруги</v-chip>
        </div>
      </td>
      <td style="vertical-align: bottom">
        <div v-if="typeof parents[1]!=='undefined'">
          <client-family-parent-tree :parents="parents[1].parents" :index="index+1"/>
          <div class="mt-4 text-left">
            <a :href="'../../'+parents[1].id+'/family/'">
              {{parents[1].lastName}} {{parents[1].firstName}} {{parents[1].secondName}}
            </a>
            <span v-if="index<=2" class="ml-2">
              {{index===0 ? parents[1].userProfile.gender==='male' ? '(Отец)' : '(Мать)' : ''}}
              {{index===1 ? parents[1].userProfile.gender==='male' ? '(Дедушка)' : '(Бабушка)' : ''}}
              {{index===2 ? parents[1].userProfile.gender==='male' ? '(Продедушка)' : '(Пробабушка)' : ''}}
            </span>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import ClientFamilyParentTree from "@/views/User/Client/ClientFamilyParentTree";

export default {
  name:'ClientFamilyParentTree',
  components: {ClientFamilyParentTree},
  props:['parents', 'index']
}
</script>