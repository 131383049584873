<template>
  <layout-view navTitle="Схемы расчета зарплат">
    <template v-slot:nav>
      <com-branch-autocomplete-single class="mx-4 mt-1" :select.sync="comBranch"/>
      <progress-page v-if="salarysState.isLoading" />
      <v-list v-else dense class="py-0">
        <v-list-item
            v-for="(v, i) in comBranch===null ? salarys : salarys.filter(v1=>v1.comBranch===null || parseInt(v1.comBranch.id)===comBranch.id)" :key="i"
            @click="salarySelect(v)"
            :class="v.id===salary.id ? 'v-list-item--active' : ''">
          <v-list-item-content>
            <v-row no-gutters>
              <v-col cols="auto" align-self="center">
                <v-list-item-title>{{$tools.date(v.begin)}}</v-list-item-title>
              </v-col>
              <v-col>
                <chip v-if="v.comBranch!==null" small :color="v.comBranch.color" dark class="ml-2">{{v.comBranch.name}}</chip>
              </v-col>
              <v-col cols="auto">
                <chip v-if="v.status==='active'" small color="success" dark>Активен</chip>
              </v-col>
              <v-col v-if="v.salaryTemplate!==null" cols="12" class="caption mt-1">
                <chip small>Привязан профиль: {{v.salaryTemplate.name}}</chip>
              </v-col>
              <v-col v-if="!$tools.isEmpty(v.comment)" cols="12" class="caption mt-1">
                <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                {{v.comment}}
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:content="{height}">
      <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
        <v-toolbar-title class="body-2 font-weight-medium mr-2">Схема расчета зарплаты</v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="salary.salaryTemplate===null" small depressed class="mr-2" @click="salaryTemplatesAttachOpen=true">Привязать профиль</v-btn>
        <btn-small-accept v-if="salary.salaryTemplate!==null" btn-class="mr-2" name="Отвязать профиль" text="Вы уверены что хотите отвязать схему от профиля?" small depressed class="mr-2" @accept="salaryDetach()"/>
        <salary-templates name="Привязать профиль" :open.sync="salaryTemplatesAttachOpen" @selected="(v)=>{salaryAttach=v;}"/>

        <v-btn :disabled="salary.salaryTemplate!==null" small depressed class="mr-2" @click="salaryTemplatesOpen=true">Загрузить профиль</v-btn>
        <salary-templates name="Загрузить профиль" :open.sync="salaryTemplatesOpen" @selected="(v)=>{salary=v;salary.salaryTemplate=null;salary.salaryServices=v.salaryTemplateServices}"/>
        <v-btn :disabled="salary.salaryTemplate!==null" small depressed class="mr-2" @click="salaryTemplatesDeleteOpen=true">Удалить профиль</v-btn>
        <salary-template-delete :open.sync="salaryTemplatesDeleteOpen"/>

        <v-btn small depressed @click="salaryTemplatesSaveOpen=true">Сохранить профиль</v-btn>
        <salary-templates-save :open.sync="salaryTemplatesSaveOpen" :salary="salary"/>
        <dot/>
        <btn-small-accept name="Удалить" btnClass="mr-2" @accept="salaryDelete()" text="Рекомендуется удалять только если схема была создана по ошибке"/>
        <v-btn @click="salarySave()" :loading="salarySaveState.isLoading" small depressed color="primary">Сохранить</v-btn>
      </v-toolbar>

      <div class="overflow-x-hidden">
        <v-row no-gutters>
          <v-col cols="4" class="pb-0">
            <v-sheet :height="height-48" class="overflow-y-auto pa-4">
              <v-row class="rowSmall">
                <v-col>
                  <date name="С даты" :value.sync="salary.begin" class="mb-4" />
                </v-col>
                <v-col>
                  <com-branch-autocomplete-single :select.sync="salary.comBranch"/>
                </v-col>
                <v-col cols="12" v-if="salary.salaryTemplate!==null && typeof salary.salaryTemplate!=='undefined'" class="mt-n7">
                  <alert>Настройки схемы переопределы профилем<br/>"{{salary.salaryTemplate.name}}"</alert>
                </v-col>
              </v-row>
              <text-area-field name="Комментарий" :disabled="salary.salaryTemplate!==null" :value.sync="salary.comment" />
              <v-row class="rowSmall">
                <v-col>
                  <sheet-group :active="salary.createVisitMode!=='disabled'">
                    <radios
                        name="За создание визита"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[{id:'disabled',name:'Не начислять'},{id:'fixed',name:'Фиксированно за каждый визит'},{id:'percent',name:'Процент от суммы визита'}]"
                        :select="{id:salary.createVisitMode}"
                        @update:select="(v)=>salary.createVisitMode=v.id"
                    />
                    <text-field
                        v-if="salary.createVisitMode!=='disabled'"
                        :disabled="salary.salaryTemplate!==null"
                        :name="salary.createVisitMode==='fixed' ? 'Сумма' : 'Процент'"
                        :value.sync="salary.createVisit"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col>
                  <sheet-group :active="salary.refMode!=='disabled'">
                    <radios
                        name="За направление"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[{id:'disabled',name:'Не начислять'},{id:'fixed',name:'Фиксированно за каждое направление'},{id:'percent',name:'Процент от суммы визита'}]"
                        :select="{id:salary.refMode}"
                        @update:select="(v)=>salary.refMode=v.id"
                    />
                    <text-field
                        v-if="salary.refMode!=='disabled'"
                        :disabled="salary.salaryTemplate!==null"
                        :name="salary.refMode==='fixed' ? 'Сумма' : 'Процент'"
                        :value.sync="salary.ref"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col>
                  <sheet-group :active="salary.regMode!=='disabled'">
                    <radios
                        name="За оформление"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[{id:'disabled',name:'Не начислять'},{id:'fixed',name:'Фиксированно за каждый визит'},{id:'percent',name:'Процент от суммы визита'}]"
                        :select="{id:salary.regMode}"
                        @update:select="(v)=>salary.regMode=v.id"
                    />
                    <text-field
                        v-if="salary.regMode!=='disabled'"
                        :disabled="salary.salaryTemplate!==null"
                        :name="salary.regMode==='fixed' ? 'Сумма' : 'Процент'"
                        :value.sync="salary.reg"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col>
                  <sheet-group :active="salary.renMode!=='disabled'">
                    <radios
                        name="За оказание услуги"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[{id:'disabled',name:'Не начислять'},{id:'fixed',name:'Фиксированно за каждую услугу'},{id:'percent',name:'Процент от суммы услуги'}]"
                        :select="{id:salary.renMode}"
                        @update:select="(v)=>salary.renMode=v.id"
                    />
                    <text-field
                        v-if="salary.renMode!=='disabled'"
                        :disabled="salary.salaryTemplate!==null"
                        :name="salary.renMode==='fixed' ? 'Сумма' : 'Процент'"
                        :value.sync="salary.ren"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col cols="6">
                  <sheet-group>
                    <radios
                        name="Ставка"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[
                              {id:'salaryMonth',name:'Оклад в месяц'},
                              {id:'salaryHour',name:'Оклад в час'},
                              {id:'salaryDay',name:'Оклад в день'},                           
                              {id:'salaryMinHour',name:'Минимальная ставка в час'},
                              {id:'salaryMinDay',name:'Минимальная ставка в день'},
                              {id:'salaryMinMonth',name:'Минимальная ставка в месяц'},
                          ]"
                        :select="{id:salary.rateMode}"
                        @update:select="(v)=>salary.rateMode=v.id"
                    />
                    <text-field
                        name="Сумма"
                        :disabled="salary.salaryTemplate!==null"
                        :value.sync="salary.rate"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col cols="6">
                  <sheet-group :active="salary.rateMaxMode!=='disabled'">
                    <radios
                        name="Максимальный лимит"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[
                              {id:'disabled',name:'Отключить'},
                              {id:'salaryMaxMonth',name:'В месяц'},
                          ]"
                        :select="{id:salary.rateMaxMode}"
                        @update:select="(v)=>salary.rateMaxMode=v.id"
                    />
                    <text-field
                        v-if="salary.rateMaxMode!=='disabled'"
                        name="Сумма"
                        :disabled="salary.salaryTemplate!==null"
                        :value.sync="salary.rateMax"
                        format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                    />
                  </sheet-group>
                </v-col>
                <v-col>
                  <sheet-group>
                    <radios
                        name="Учитывать скидку пациента на оказанную услугу при расчете з/п"
                        :disabled="salary.salaryTemplate!==null"
                        :items="[
                          {id:false,name:'Нет'},
                          {id:true,name:'Да'},
                      ]"
                        :select="{id:salary.isRateServiceDiscount}"
                        @update:select="(v)=>salary.isRateServiceDiscount=v.id"
                    />
                  </sheet-group>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col cols="8" class="pb-4 px-4">
            <sheet-group>
              <template v-slot:header>
                <v-toolbar dense elevation="0" color="transparent" max-height="48px">
                  <v-toolbar-title class="body-2 font-weight-medium mr-2">Услуги</v-toolbar-title>
                  <v-spacer/>
                  <v-btn :disabled="salary.salaryTemplate!==null" small depressed class="mr-2" @click="serviceAddOpen=true">Добавить услугу</v-btn>
                  <visit-service-add
                      :open.sync="serviceAddOpen"
                      @complete="(arr)=>{
                      arr.forEach(v1=>{
                        if(salary.salaryServices.filter(v2=>parseInt(v2.service.id)===parseInt(v1.id)).length===0)
                          salary.salaryServices.push({
                            service: {id:v1.id,name:v1.name,priceTotal:v1.price},
                            renMode: 'fixed',
                            ren: '100.00'
                          })
                      })
                    }"
                  />
                  <btn-small-accept
                    name="Удалить услугу"
                    text="Вы уверены что хотите удалить отмеченные услуги?"
                    :disabled="salaryServicesSelects.length===0 || salary.salaryTemplate!==null"
                    @accept="
                      salary.salaryServices=salary.salaryServices.filter(v1=>salaryServicesSelects.filter(v2=>v1.service.id===v2.service.id).length===0);
                      salaryServicesSelects = [];
                      tableKey++;
                    "
                  />
                </v-toolbar>
              </template>
              <v-data-table
                  :key="tableKey"
                  class="tableMultipleSelect mt-n4"
                  dense
                  :headers="[
                  { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
                  { text: 'Название', value: 'name' },
                  { text: 'Стоимость', value: 'price', class:'text-no-wrap', sortable: false },
                  { text: 'За оказание услуги', value: 'ren', class:'text-no-wrap' },
                ]"
                  :items="salary.salaryServices"
                  :footer-props="{'items-per-page-options': [100,500,1000]}"
                  :items-per-page="100"
                  :height="height-164"
                  sort-by="sort"
                  show-select
                  fixed-header
              >
                <template v-slot:header.data-table-select>
                  <v-checkbox :disabled="salary.salaryTemplate!==null" v-model="selectedAll" :indeterminate="selectedAllIndeterminate" class="ma-0 pa-0" dense hide-details />
                </template>
                <template v-slot:item="{item}">
                  <tr>
                    <td>
                      <v-checkbox
                          :disabled="salary.salaryTemplate!==null"
                          @click.stop="
                          salaryServicesSelects.filter(v=>v.service.id===item.service.id).length>0 ?
                          salaryServicesSelects=salaryServicesSelects.filter(v=>v.service.id!==item.service.id) :
                          salaryServicesSelects.push(item)
                        "
                          :input-value="salaryServicesSelects.filter(v=>v.service.id===item.service.id).length>0"
                          class="ma-0 pa-0" dense hide-details
                      />
                    </td>
                    <td>{{item.service.id}}</td>
                    <td class="text-wrap">{{item.service.name}}</td>
                    <td>{{$tools.price(item.service.priceTotal)}}</td>
                    <td class="pt-2">
                      <v-sheet color="transparent" width="296">
                        <v-row class="rowSmall">
                          <v-col cols="auto">
                            <v-sheet color="transparent" width="160">
                              <autocomplete-single
                                  :disabled="salary.salaryTemplate!==null"
                                  name="Тип"
                                  :items="[{id:'fixed',name:'Сумма'}, {id:'percent',name:'Процент'}]"
                                  :select="{id:item.renMode}"
                                  @update:select="(v)=>{item.renMode=v?.id}"
                              />
                            </v-sheet>
                          </v-col>
                          <v-col cols="auto">
                            <v-sheet color="transparent" width="120">
                              <text-field
                                  :disabled="salary.salaryTemplate!==null"
                                  :name="item.renMode==='fixed' ? 'Сумма' : 'Процент'"
                                  :value.sync="item.ren"
                                  format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="100, 10.10"
                                  class="mb-0"
                              />
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </sheet-group>
          </v-col>
        </v-row>
      </div>
    </template>
  </layout-view>

</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import LayoutView from "@/components/LayoutView.vue";
import Date from "@/componentsV2/base/Date.vue";
import moment from "moment";
import TextAreaField from "@/componentsV2/base/TextAreaField.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import Radios from "@/componentsV2/base/Radios.vue";
import SheetGroup from "@/componentsV2/base/SheetGroup.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";
import axios from "axios";
import SalaryTemplatesSave from "@/views/Salary/SalaryTemplatesSave.vue";
import SalaryTemplates from "@/views/Salary/SalaryTemplates.vue";
import SalaryTemplateDelete from "@/views/Salary/SalaryTemplateDelete.vue";
import Dot from "@/componentsV2/base/Dot.vue";
import ComBranchAutocompleteSingle from "@/componentsV2/custom/ComBranchAutocompleteSingle.vue";
import Chip from "@/componentsV2/base/Chip.vue";
import {
  Entity_ComBranch,
  Entity_Salary, Entity_SalaryService,
  Entity_SalaryTemplate,
  Entity_SalaryTemplateService,
  Entity_Service,
  Entity_User
} from "../../../EntityStoreCacheService";
import ProgressPage from "@/components/ProgressPage.vue";
import Alert from "@/componentsV2/base/Alert.vue";

export default {
  components: {
    Alert,
    ProgressPage,
    Chip,
    ComBranchAutocompleteSingle,
    Dot,
    SalaryTemplateDelete,
    SalaryTemplates,
    SalaryTemplatesSave,
    VisitServiceAdd,
    BtnSmallAccept,
    AutocompleteSingle,
    SheetGroup,
    Radios,
    TextField,
    TextAreaField,
    Date,
    LayoutView
  },
  data: () => ({
    comBranch:null,
    salarys:[],
    salarysState:new State(),
    salary:{
      id:null,
      comment: null,
      begin: moment().format('DD.MM.YYYY'),
      comBranch:null,
      createVisitMode: "fixed",
      createVisit: null,
      renMode: "fixed",
      ren: null,
      refMode: "fixed",
      ref: null,
      regMode: "fixed",
      reg: null,
      rateMode: "salaryMonth",
      rate: null,
      rateMaxMode: "disabled",
      rateMax: null,
      isRateServiceDiscount: false,
      salaryServices: [],
      salaryTemplate: null
    },
    salaryAttach:null,

    tableKey:0,

    salaryServicesSelects:[],
    serviceAddOpen:false,
    salarySaveState:new State(),

    templates:[],

    salaryTemplatesAttachOpen:false,
    salaryTemplatesOpen:false,
    salaryTemplatesDeleteOpen:false,
    salaryTemplatesSaveOpen:false,
  }),
  watch:{
    salaryAttach(){
      if(this.salaryAttach!==null)
        this.salaryAttachAction();
    }
  },
  computed:{
    selectedAll:{
      get() {
        return this.salary.salaryServices.length>0 && this.salary.salaryServices.length===this.salaryServicesSelects.length;
      },
      set(v) {
        this.salaryServicesSelects = v ? this.salary.salaryServices : [];
      }
    },
    selectedAllIndeterminate:{
      get() {
        return this.salaryServicesSelects.length>0 && this.salaryServicesSelects.length!==this.salary.salaryServices.length;
      }
    },
  },
  mounted() {
    this.salaryListLoad(()=>{
      if(this.salarys.length>0)
        this.salarySelect(this.salarys[0]);
    });
  },
  methods: {
    salaryListLoad(callback=null){
      new Entity_Salary()
          .selects(Entity_Salary.id)
          .selects(Entity_Salary.comment)
          .selects('status')
          .selects(Entity_Salary.begin)
          .selects(Entity_Salary.createVisitMode)
          .selects(Entity_Salary.createVisit)
          .selects(Entity_Salary.renMode)
          .selects(Entity_Salary.ren)
          .selects(Entity_Salary.refMode)
          .selects(Entity_Salary.ref)
          .selects(Entity_Salary.regMode)
          .selects(Entity_Salary.reg)
          .selects(Entity_Salary.rateMode)
          .selects(Entity_Salary.rate)
          .selects(Entity_Salary.rateMaxMode)
          .selects(Entity_Salary.rateMax)
          .selects(Entity_Salary.isRateServiceDiscount)
          .selects([Entity_Salary.comBranch, Entity_ComBranch.id].join('.'))
          .selects([Entity_Salary.comBranch, Entity_ComBranch.name].join('.'))
          .selects([Entity_Salary.comBranch, Entity_ComBranch.color].join('.'))
          .selects([Entity_Salary.salaryServices, Entity_SalaryService.renMode].join('.'))
          .selects([Entity_Salary.salaryServices, Entity_SalaryService.ren].join('.'))
          .selects([Entity_Salary.salaryServices, Entity_SalaryService.service, Entity_Service.id].join('.'))
          .selects([Entity_Salary.salaryServices, Entity_SalaryService.service, Entity_Service.name].join('.'))
          .selects([Entity_Salary.salaryServices, Entity_SalaryService.service, Entity_Service.priceTotal].join('.'))

          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.id].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.name].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.comment].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.createVisitMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.createVisit].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.renMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.ren].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.refMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.ref].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.regMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.reg].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.rateMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.rate].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.rateMaxMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.rateMax].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.isRateServiceDiscount].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.salaryTemplateServices, Entity_SalaryTemplateService.renMode].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.salaryTemplateServices, Entity_SalaryTemplateService.ren].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.salaryTemplateServices, Entity_SalaryTemplateService.service, Entity_Service.id].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.salaryTemplateServices, Entity_SalaryTemplateService.service, Entity_Service.name].join('.'))
          .selects([Entity_Salary.salaryTemplate, Entity_SalaryTemplate.salaryTemplateServices, Entity_SalaryTemplateService.service, Entity_Service.priceTotal].join('.'))
          .order(Entity_Salary.begin, false)
          .filterAnd([Entity_Salary.entity, Entity_Salary.user, Entity_User.id].join('.')+'=?', this.$router.currentRoute.params.id)
          .page(1)
          .onPage(1000)
          .addStateCallback((v)=> this.salarysState.setRequestBuilderState(v))
          .addStateCallback((r)=>{
            if(r.success){
              this.salarys = r.items;
              this.salarys.forEach(v=>{
                if(v.salaryTemplate!==null){
                  v.comment = v.salaryTemplate.comment;
                  v.createVisitMode = v.salaryTemplate.createVisitMode;
                  v.createVisit = v.salaryTemplate.createVisit;
                  v.renMode = v.salaryTemplate.renMode;
                  v.createVisit = v.salaryTemplate.createVisit;
                  v.ren = v.salaryTemplate.ren;
                  v.refMode = v.salaryTemplate.refMode;
                  v.ref = v.salaryTemplate.ref;
                  v.regMode = v.salaryTemplate.regMode;
                  v.reg = v.salaryTemplate.reg;
                  v.rateMode = v.salaryTemplate.rateMode;
                  v.rate = v.salaryTemplate.rate;
                  v.rateMaxMode = v.salaryTemplate.rateMaxMode;
                  v.rateMax = v.salaryTemplate.rateMax;
                  v.isRateServiceDiscount = v.salaryTemplate.isRateServiceDiscount;
                  v.salaryServices = v.salaryTemplate.salaryTemplateServices;
                }
              })
              if(callback!==null)
                callback();
            }
          })
          .request()
    },
    salarySave(){
      this.salarySaveState.stateLoading();
      let data = this.$tools.copy(this.salary)
      data.userId = parseInt(this.$router.currentRoute.params.id)
      data.begin = moment(this.salary.begin, 'DD.MM.YYYY').format('YYYY-MM-DD')
      data.comBranchId = typeof data.comBranch==='undefined' || data.comBranch===null ? null : data.comBranch.id;
      delete data.comBranch;
      data.salaryTemplateId = this.salary.salaryTemplate===null ? null : this.salary.salaryTemplate.id;
      delete data.salaryTemplate;

      if(this.salary.salaryServices.filter(v=>typeof v.renMode==='undefined').length>0){
        this.$store.commit('alertError', 'Не у всех услуг задан тип');
        return null;
      }

      axios.post(Api.host+'/salary/', data).then(()=>{
        this.salarySaveState.stateSuccess();
        this.$store.commit('alertSuccess', 'Схема обновлена');
        this.salaryListLoad();
      }).catch(()=>{
        this.salarySaveState.stateError();
      });
    },
    salaryDelete(){
      this.salarySaveState.stateLoading();
      axios.delete(Api.host+'/salary/'+this.salary.id+'/').then(()=>{
        this.salarySaveState.stateSuccess();
        this.$store.commit('alertSuccess', 'Схема удалена');
        this.salaryListLoad();
      }).catch(()=>{
        this.salarySaveState.stateError();
      });
    },
    salaryAttachAction(){
      let data = this.$tools.copy(this.salary)

      axios.put(Api.host+'/salary/',{
        userId: parseInt(this.$router.currentRoute.params.id),
        begin: moment(this.salary.begin, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        comBranchId: typeof data.comBranch==='undefined' || data.comBranch===null ? null : data.comBranch.id,
        salaryTemplateId: this.salaryAttach.id
      }).then((r)=>{
        this.salarySaveState.stateSuccess();
        this.salaryListLoad(()=>{
          this.salarySelect(this.salarys.filter(v=>v.id===r.data.salaryId)[0]);
        });
        this.$store.commit('alertSuccess', 'Схема привязана к профилю');
      }).catch(()=>{
      });
    },
    salaryDetach(){
      axios.put(Api.host+'/salary/'+this.salary.id+'/detach/').then(()=>{
        this.salaryListLoad(()=>{
          this.salarySelect(this.salarys.filter(v=>v.id===this.salary.id)[0]);
        });
        this.$store.commit('alertSuccess', 'Схема отвязана от профиля');
      }).catch(()=>{
      });
    },
    salarySelect(v){
      this.salary=this.$tools.copy(v);
      this.salary.begin=this.$tools.dateTimeFormat(this.$tools.copy(v).begin,'DD.MM.YYYY');
      if(this.salary.comBranch!==null)
        this.salary.comBranch.id = parseInt(this.salary.comBranch.id)
      this.salary.salaryServices = this.salary.salaryServices.sort((a,b)=>a.service.name.localeCompare(b.service.name));
    }
  }
};
</script>