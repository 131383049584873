<template>
  <div class="border-top-bottom">
    <v-row class="no-gutters">
      <v-col cols="2" class="py-3 pl-3">
          <h4>{{medRecordTemplateField.name}}</h4>
      </v-col>
      <v-col cols="8" class="border-left-right py-3 px-3">

          <v-textarea
              v-if="isEdit"
              v-model="medRecordTemplateField.medRecordValueValue"
              @change="fieldRender()"
              @keyup="fieldAddHistory()"
              rows="1" outlined dense auto-grow hide-details="auto" clearable    
          />
          <template v-else>
            <div
                v-for="(fieldValue, index) in fieldValues"
                :key="'editorField_'+medRecordTemplateField.id+'_'+index"
                class="d-inline"
            > 
              <div v-if="typeof fieldValue.text!=='undefined'" class="d-inline" v-html="fieldValue.text"></div>
              <med-record-update-table v-else-if="typeof fieldValue.table!=='undefined'"
                 :editor-field="fieldValue"
                 :index="index"
                 :medRecordTemplateFieldValues="medRecordTemplateField.medRecordTemplateFieldValues"
              />
              
              <blood-test v-else-if="medRecordTemplateField.medRecordValueValue.includes(':Общий анализ крови}}')||medRecordTemplateField.medRecordValueValue.includes('Лейкоцитарная формула</col>')" :medRecordValue="medRecordTemplateField.medRecordValueValue" :editor-field="fieldValue" :birth="birth" :gender="gender"/>

              <div v-else class="d-inline medRecordValueView">
                <med-record-update-value-number-type v-if="fieldValue.type==='number'" :editor-field="fieldValue" :userId="userId"/>
                <med-record-update-value-text-type v-if="fieldValue.type==='text'" :editor-field="fieldValue" :userId="userId"/>
                <med-record-update-value-date-type v-if="fieldValue.type==='date'" :editor-field="fieldValue"/>
                <med-record-update-value-time-type v-if="fieldValue.type==='time'" :editor-field="fieldValue"/>
                <med-record-update-value-file-type v-if="fieldValue.type==='file'" :editor-field="fieldValue"/>
                <med-record-update-value-icd-type v-if="fieldValue.type==='icd'" :editor-field="fieldValue"/>
              </div>
            </div>
          </template>
        
      </v-col>
      <v-col cols="2" class="py-3 d-flex align-center justify-center">
        <v-sheet>
          <div class="d-flex caption">
            <v-btn
              @click="isEdit=!isEdit"
              fab small depressed class="mr-2"
              :title="isEdit ? 'Вид: Текст': 'Вид: Значения'"
            >
              <!-- Вид: {{isEdit ? 'Текст': 'Значения'}} -->
              <v-icon small>{{isEdit ? 'mdi-signature-text' : 'mdi-view-grid-plus'}}</v-icon>
            </v-btn>
            <v-btn
                :disabled="medRecordTemplateField.medRecordValueValue.length===0"
                @click="fieldClear()"
                class="mr-2" fab small depressed title="Очистить">
              <!-- Очистить -->
              <v-icon small>mdi-backspace</v-icon>
            </v-btn>
            <v-btn
                :disabled="valueHistory.length<=1"
                @click="fieldHistoryBack()"
                class="mr-2" fab small depressed title="Отменить">
              <!-- Отменит -->
              <v-icon small>mdi-history</v-icon>
            </v-btn>
            <v-btn
                :disabled="medRecordTemplateField.medRecordValueValue===medRecordTemplateField.value"
                @click="fieldRender(true)"
                 fab small depressed  title="Сбросить">
              <!-- Сбросить -->
              <v-icon small>mdi-lock-reset</v-icon>
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MedRecordUpdateValueNumberType from "@/views/MedRecord/MedRecordUpdateValueNumberType";
import MedRecordUpdateValueTextType from "@/views/MedRecord/MedRecordUpdateValueTextType";
import MedRecordUpdateValueDateType from "@/views/MedRecord/MedRecordUpdateValueDateType";
import MedRecordUpdateValueTimeType from "@/views/MedRecord/MedRecordUpdateValueTimeType";
import MedRecordUpdateValueFileType from "@/views/MedRecord/MedRecordUpdateValueFileType";
import MedRecordUpdateValueIcdType from "@/views/MedRecord/MedRecordUpdateValueIcdType";
import MedRecordUpdateTable from "@/views/MedRecord/MedRecordUpdateTable";
//calcs
import BloodTest from "@/views/MedRecord/calcs/BloodTest";

export default {
  components: {
    MedRecordUpdateTable,
    MedRecordUpdateValueFileType,
    MedRecordUpdateValueTimeType,
    MedRecordUpdateValueDateType,
    MedRecordUpdateValueTextType,
    MedRecordUpdateValueNumberType,
    MedRecordUpdateValueIcdType,
    BloodTest
  },
  props: ['medRecordTemplateField','birth','gender','userId'],
  data: () => ({
    isEdit:false,
    valueHistory: [],
    fieldValues:[],
  }),
  mounted(){
    this.fieldRender();
    this.fieldAddHistory();
  },
  methods: {
    fieldRender(reset){
      if (reset){
        this.medRecordTemplateField.medRecordValueValue = this.medRecordTemplateField.value;
        this.fieldAddHistory();
      }
      if (this.medRecordTemplateField.medRecordValueValue.length===0)
        this.isEdit = true;

      let fields = [];

      let strOriginal = this.medRecordTemplateField.medRecordValueValue;

      let tables = [];
      let tableTag = '__table__'

     
      strOriginal = strOriginal.replaceAll(/<table>[\s\S]*?<\/table>/g, (match)=>{
        tables.push(match);
        return tableTag;
      });

      let str = strOriginal;
      let tagStart ='{{';
      let tagEnd ='}}';
      let tagStartFound = 0;
      let tagEndFound = 0;

      let isWork = true;
      while(isWork){
        tagStartFound = str.indexOf(tagStart);
        if (tagStartFound===-1){
          isWork = false;
        } else {
          if (tagStartFound>0){
            let s = strOriginal.length-str.length;
            let e = (strOriginal.length-str.length)+tagStartFound;
            if (e>s)
              fields.push({
                text:strOriginal.substring(s, e).replaceAll(/(?:\r\n|\r|\n)/g, '<br>')
              });
          }

          str = str.substring(tagStartFound+tagStart.length, str.length);
          tagEndFound = str.indexOf(tagEnd);
          let tagBody = str.substring(0, tagEndFound);
          let tagId = tagBody.substring(0, tagBody.indexOf(':'));
          let tagValue = tagBody.substring(tagBody.indexOf(':')+1, tagBody.length);

          this.medRecordTemplateField.medRecordTemplateFieldValues.forEach((v2)=> {
            if (v2.id+''===tagId){
              let options = [];
              v2.medRecordTemplateFieldValueOptions.forEach((v3)=> {
                options.push({
                  id:v3.id,
                  value:v3.value,
                  sort:v3.sort
                });
              });
              fields.push({
                id:v2.id,
                type:v2.type,
                value:v2.value,
                options:options,
                currentValue:tagValue,
                customValue:'',
              });
            }
          });
          str = str.substring(tagEndFound+tagEnd.length, str.length);
        }
      }

      let s = strOriginal.length-str.length;
      let e = strOriginal.length;
      if (e>s)
        fields.push({
          text:strOriginal.substring(s, e).replaceAll(/(?:\r\n|\r|\n)/g, '<br>')
        });

      //TABLES
      let index = 0;
      fields.forEach((v)=>{
        if (typeof v.text!=='undefined'){
          if (v.text.indexOf(tableTag)!==-1){
            let arr = v.text.split(tableTag);
            v.text = '';
            arr.forEach((v1)=>{
              fields.push({
                text:v1
              });
              if (typeof tables[index]!='undefined')
                fields.push({
                  table:tables[index++]
                });
            });
          }
        }
      });

      //fix < >
      // function unescapeTags(string) {
      //   var pattern = /{{(.*?)}}/g;
      //   var matches = string.match(pattern);
        
      //   if (matches) {
      //     var regex_lt = /&lt;/g; // Проверка на символ '&lt;'
      //     var regex_gt = /&gt;/g; // Проверка на символ '&gt;'
          
      //     string = string.replace(regex_lt, '<');
      //     string = string.replace(regex_gt, '>');


      //     console.log(string);
      //   }

      //   return string;
      // }

      // fields.forEach((v)=>{
      //   var substring = "{{381:";//exclude blood test  
      //   if (typeof v.text!=='undefined'&&v.text){
      //     v.text = unescapeTags(v.text );
      //     console.log(v.text)
      //   }
      //   if (typeof v.table!=='undefined'&&v.table&&!v.table.includes(substring)){
      //     v.table = unescapeTags(v.table );
      //   }
      // });


      this.fieldValues = fields;
    },
    
    fieldClear(){
      this.medRecordTemplateField.medRecordValueValue='';
      this.fieldRender();
      this.fieldAddHistory();
    },


    fieldAddHistory(){
      if (
          this.valueHistory.length===0 ||
          (this.valueHistory.length>0 && this.valueHistory[this.valueHistory.length-1]!==this.medRecordTemplateField.medRecordValueValue)
      )
        this.valueHistory.push(this.medRecordTemplateField.medRecordValueValue);
    },
    fieldHistoryBack(){
      if (this.valueHistory.length>0){
        this.medRecordTemplateField.medRecordValueValue = this.valueHistory[this.valueHistory.length-2];
        this.valueHistory.splice(-1);
        this.fieldRender();
      }
    },

    fieldUpdateSelect(editorField, option){
      editorField.currentValue = option.value;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdateSelectCustom(editorField){
      editorField.currentValue = editorField.customValue;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdateRemove(editorField){
      editorField.remove=true;
      this.fieldUpdatesToMedRecordValueValue();
    },
    fieldUpdateMore(editorField){
      editorField.append=true;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdatesToMedRecordValueValue(){
      let value = '';
      this.fieldValues.forEach((v)=>{
        if (typeof v.text!=='undefined')
          value += v.text.replaceAll('<br>', '\n');
        if (typeof v.table!=='undefined'){
          value += v.table;
        }
        if (typeof v.remove==='undefined'){
          if (typeof v.currentValue!=='undefined')
            value += '{{'+v.id+':'+v.currentValue+'}}';
          if (v.append){
            value += ', {{'+v.id+':'+v.currentValue+'}}';
          }
        } else {
          value += '{{removeComma}}';
        }
      });

      value = value.replace(', {{removeComma}}','');
      value = value.replace('{{removeComma}}, ','');
      value = value.replace('{{removeComma}}','');


      this.medRecordTemplateField.medRecordValueValue = value;
      this.fieldRender();
      this.fieldAddHistory();
    }
  }
}
</script>

<styles lang="scss" scoped>
  .border-top-bottom {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: -1px;
  }
  .border-left-right {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
</styles>