<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="itemsTotal"
        :loading="loadState.isLoading"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [5, 25, 50, 100]}"
        :items-per-page="onPage"
        :class="tableClass"
        dense
        :show-select="showSelect"
        :height="height-(16*6)"
        :fixed-header="typeof height!=='undefined'"
    >
      <template v-slot:header.data-table-select>
        <slot name="selectAll" v-bind:items="items"/>
      </template>
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-row no-gutters>
          <v-col cols="auto">
            <v-toolbar v-if="typeof title!=='undefined'" class="bar" dense elevation="0" color="transparent" max-height="48px">
              <v-toolbar-title class="body-2 font-weight-medium">{{ title }}</v-toolbar-title>
            </v-toolbar>
          </v-col>
          <v-col>
            <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :itemsPerPageOptions="[5, 25, 50, 100]"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                />
          </v-col>
          <v-col v-if="!!$slots.actions" cols="12" class="px-4 mb-2">
            <slot name="actions"/>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <slot name="item" v-bind:item="item" v-bind:items="items"/>
      </template>
      
      <template v-slot:body.append>
        <slot name="add-item" v-bind:items="items"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";

export default {
  props: [
    'url',
    'title',
    'headers',
    'tableClass',
    'showSelect',
    'height',
    'returnItems'
  ],
  data: () => ({
    loadState: new State(),
    options:{},
    urlLast:null,

    items: [],
    itemsTotal: 0,
    onPage:25,
  }),
  watch: {
    options: {
      handler () {
        this.load()
      },
    },
    url(){
      this.load();
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(force=false){
      if (!force && this.url===null){
        this.urlLast = null;
        return null;
      }

      const {
        //sortBy,
        //sortDesc,
        page,
        itemsPerPage
      } = this.options
      let a = '';
      a += (this.url.indexOf('?')>0 ? '&' : '?')+'page='+page;
      a += '&onPage='+itemsPerPage;

      if (!force && this.url+a===this.urlLast)
        return null;

      this.urlLast = this.url+a;
      this.loadState.stateLoading();
      axios.get(this.urlLast).then(v => {
        if (v.data.data)
        this.items = v.data.data;
        this.$emit('update:returnItems', this.items);//return items to parent component
        this.items.forEach((v)=>{
          v._selected=false;
        });

        this.itemsTotal = v.data.total;
        this.loadState.stateSuccess();
      }).catch(e =>{
        this.loadState.stateError();
        Api.responseError(e)
      });

    },

    reload(){
      this.load(true);
    },
  }
}
</script>
