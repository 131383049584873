<template>
    <v-text-field 
      v-model="innNumber" 
      :value="inn"
      label="ИНН" 
      hint="12 цифр" 
      outlined 
      dense 
      hide-details="auto" 
      counter
      maxlength="12"
      :rules="typeof required != 'undefined' ? [rules.required] : []"
      @keyup="onlyNumber(innNumber)"
      />
  </template>

<script>

export default {
  props: [
    'inn','required'
  ],
  data:()=>({
    rules: {
      required: innNumber =>innNumber ? innNumber.length == 12 || '12 цифр' : '',
    },
  }),

  computed:{
    innNumber: {
      get(){
        if(typeof this.inn == 'undefined') {
          return ''
        } else {   
          return this.inn
        }
      },
      set(i){
        this.$emit('update:inn', i);
      }      
    }
  },
  methods:{
    onlyNumber(value){
        this.innNumber = value.replace(/[^0-9]/g, '')
    }
  }
}
</script>